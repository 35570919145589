import { useEffect, useState } from "react";
import getApiCall from "../Services/getApiCall";
import { toast, ToastContainer } from "react-toastify";
import { Pagination } from 'rsuite';
import { Link } from "react-router-dom";
import 'rsuite/Pagination/styles/index.css';

const Dashboard = () => {
  const [allData, setAllData] = useState({});

  useEffect(() => {
    async function getData() {
      let docs = await getApiCall("/admin/dashboard", {}, true);
      if (docs.status) {
        setAllData(docs.data)
      } else {
        toast.error(docs.message != undefined ? docs.message : "Network error!!")
      }
    }
    getData()
  }, []);



  return (
    <>
      {/* <ToastContainer /> */}
      <ul className="ulfive-row list-inline d-flex flex-wrap">
        <li>
          <div className="media-dashboard bg-success-grandiant">
            <Link to="/job-seeker-management">
            <h2 className="text-white mb-1">{ allData.totalJobSeeker }</h2>
            <p className="mb-0 text-white">Total Job Seekers</p>
            </Link>
          </div>
        </li>
        <li>
          <div className="media-dashboard bg-info-grandiant">
          <Link to="/employer-management">
            <h2 className="text-white mb-1">{ allData.totalEmployer }</h2>
            <p className="mb-0 text-white">Total Employers</p>
            </Link>
          </div>
        </li>
        <li>
          <div className="media-dashboard bg-primary-grandiant">
          <Link to="/job-management">
            <h2 className="text-white mb-1">{ allData.totalOngoingJob }</h2>
            <p className="mb-0 text-white">Total On-going Jobs</p>
            </Link>
          </div>
        </li>
        <li>
          <div className="media-dashboard bg-info-grandiant">
          <Link to="/job-management">
            <h2 className="text-white mb-1">{ allData.totalCompletedJob }</h2>
            <p className="mb-0 text-white">Total Completed Jobs</p>
            </Link>
          </div>
        </li>
        <li>
          <div className="media-dashboard bg-purple-grandiant">
          <Link to="/ledger-management">
            <h2 className="text-white mb-1">+ { `₹ ${ (allData.totalSales) ? allData.totalSales : 0 } `  }</h2>
            <p className="mb-0 text-white">Total Sales</p>
            </Link>
          </div>
        </li>
      </ul>
      <div className="title mb-3">
        <h4 className="mb-0">Total Job Seekers { allData.totalJobSeeker }</h4>
      </div>
      <div className="row">
        <div className="col-6 col-sm-3 col-md-3 col-lg-3">
          <div className="media-dashboard media">
          <Link to="/job-seeker-management">
            <div className="media-body">
              <h3 className="mb-1 text-primary">{ allData.totalActiveJobSeeker }</h3>
              <p className="mb-0">Active Users</p>
            </div>
            <div className="media-right">
              <div className="dashboard-icon">
                <img src="images/3User.svg" />
              </div>
            </div>
            </Link>
          </div>
        </div>
        <div className="col-6 col-sm-3 col-md-3 col-lg-3">
          <div className="media-dashboard media">
          <Link to="/job-seeker-management">
            <div className="media-body">
              <h3 className="mb-1 text-primary">{ allData.totalInActiveJobSeeker }</h3>
              <p className="mb-0">Inactive Users</p>
            </div>
            <div className="media-right">
              <div className="dashboard-icon">
                <img src="images/3User.svg" />
              </div>
            </div>
            </Link>
          </div>
        </div>
        <div className="col-6 col-sm-3 col-md-3 col-lg-3">
          <div className="media-dashboard media">
          <Link to="/job-seeker-management">
            <div className="media-body">
              <h3 className="mb-1 text-primary">{ allData.totalPaidJobSeeker }</h3>
              <p className="mb-0">Paid Users</p>
            </div>
            <div className="media-right">
              <div className="dashboard-icon">
                <img src="images/3User.svg" />
              </div>
            </div>
            </Link>
          </div>
        </div>
        <div className="col-6 col-sm-3 col-md-3 col-lg-3">
          <div className="media-dashboard media">
          <Link to="/job-seeker-management">
            <div className="media-body">
              <h3 className="mb-1 text-primary">{ allData.totalFreeJobSeeker }</h3>
              <p className="mb-0">Free Users</p>
            </div>
            <div className="media-right">
              <div className="dashboard-icon">
                <img src="images/3User.svg" />
              </div>
            </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="title mb-3">
        <h4 className="mb-0">Total Employers { allData.totalEmployer }</h4>
      </div>
      <div className="row">
        <div className="col-6 col-sm-3 col-md-3 col-lg-3">
          <div className="media-dashboard media">
          <Link to="/employer-management">
            <div className="media-body">
              <h3 className="mb-1 text-primary">{ allData.totalActiveEmployer }</h3>
              <p className="mb-0">Active Employers</p>
            </div>
            <div className="media-right">
              <div className="dashboard-icon">
                <img src="images/3User-green.svg" />
              </div>
            </div>
            </Link>
          </div>
        </div>
        <div className="col-6 col-sm-3 col-md-3 col-lg-3">
          <div className="media-dashboard media">
          <Link to="/employer-management">
            <div className="media-body">
              <h3 className="mb-1 text-primary">{ allData.totalInActiveEmployer }</h3>
              <p className="mb-0">Inactive Employers</p>
            </div>
            <div className="media-right">
              <div className="dashboard-icon">
                <img src="images/3User-green.svg" />
              </div>
            </div>
            </Link>
          </div>
        </div>
        <div className="col-6 col-sm-3 col-md-3 col-lg-3">
          <div className="media-dashboard media">
          <Link to="/employer-management">
            <div className="media-body">
              <h3 className="mb-1 text-primary">{ allData.totalPaidEmployer }</h3>
              <p className="mb-0">Paid Employers</p>
            </div>
            <div className="media-right">
              <div className="dashboard-icon">
                <img src="images/3User-green.svg" />
              </div>
            </div>
            </Link>
          </div>
        </div>
        <div className="col-6 col-sm-3 col-md-3 col-lg-3">
          <div className="media-dashboard media">
          <Link to="/employer-management">
            <div className="media-body">
              <h3 className="mb-1 text-primary">{ allData.totalFreeEmployer }</h3>
              <p className="mb-0">Free Employers</p>
            </div>
            <div className="media-right">
              <div className="dashboard-icon">
                <img src="images/3User-green.svg" />
              </div>
            </div>
            </Link>
          </div>
        </div>
      </div>

    </>
  );
};

export default Dashboard;
