import { toast, ToastContainer } from "react-toastify";
import postApiCall from "../Services/postApiCall";

export async function Logout() {
    // confirm the user wants to logout
    try {
        if (window.confirm("Are you sure you want to logout?")) {
            let { data } = await postApiCall("/admin/logout", {}, true);
            if(data.status) {
                sessionStorage.clear();
                window.location.href = '/login';
            }
            // else {
            //     alert(data.message);
            //     return false;
            // }
        }
    }
    catch(error) {
        console.log(error);
    }
}

export function userDetails() {
    let data = sessionStorage.getItem('userDetails');
    data = JSON.parse(data);
    return data;
}
