import { useEffect, useState } from "react";
import getApiCall from "../Services/getApiCall";
import putApiCall from "../Services/putApiCall";


import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import 'rsuite/Pagination/styles/index.css';


const DataList = (props) => {
    const [record, setRecord] = useState([])
    const [request, setRequestParms] = useState({})
    
	useEffect(() => {
        
        getData()

    }, []);
	
	const setRequest = (name, e) => {
        setRequestParms({ ...request, [name]: e.target.value })
    }

    const getData = async () => {
        let docs = await getApiCall("/admin/siteinfo", {}, true);
        if (docs.status) {
            setRecord(docs.data);
        } else {
            toast.error(docs.message != undefined ? docs.message : "Network error!!")
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let data = await putApiCall("/admin/siteinfo", request, true);

        if(data.status) {
            toast.success(data.message)
        }
        else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    
    return (
        <>
            <ToastContainer/>
            <div className="page-header back-page">
                <h4> Site Information</h4>
            </div>
            <div className="account_dashboard">
                <div className="form-box">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label>Email</label>
                                    <input type="text" className="form-control form-ctrl" placeholder="Enter" defaultValue={ record.email }  onChange={(evt) => setRequest("email", evt)}/>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label>Phone</label>
                                    <input type="text" className="form-control form-ctrl" placeholder="Enter" defaultValue={ record.phone } onChange={(evt) => setRequest("phone", evt)}/>
                                </div>
                            </div>
							<div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label>Facebook Link</label>
                                    <input type="text" className="form-control form-ctrl" placeholder="Enter" defaultValue={ record.fb_link } onChange={(evt) => setRequest("fb_link", evt)}/>
                                </div>
                            </div>
							<div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label>Instagram Link</label>
                                    <input type="text" className="form-control form-ctrl" placeholder="Enter" defaultValue={ record.insta_link } onChange={(evt) => setRequest("insta_link", evt)}/>
                                </div>
                            </div>
							<div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label>Thread Link</label>
                                    <input type="text" className="form-control form-ctrl" placeholder="Enter" defaultValue={ record.thread_link } onChange={(evt) => setRequest("thread_link", evt)}/>
                                </div>
                            </div>
							<div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label>Twitter Link</label>
                                    <input type="text" className="form-control form-ctrl" placeholder="Enter" defaultValue={ record.twitter_link } onChange={(evt) => setRequest("twitter_link", evt)}/>
                                </div>
                            </div>
							<div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label>Linkedin Link</label>
                                    <input type="text" className="form-control form-ctrl" placeholder="Enter" defaultValue={ record.linkedin_link } onChange={(evt) => setRequest("linkedin_link", evt)}/>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <label>Address </label>
                            <textarea rows="6" className="form-control form-ctrl" placeholder="Enter" onChange={(evt) => setRequest("address", evt)} defaultValue={ record.address }></textarea>
                        </div>

                        <div className="row justify-content-center mt-5">
                            <div className="col-12 col-sm-4 col-md-3 col-lg-2">
                                <button type="submit" className="btn me-2 btn-sumbit btn-primary btn-block">Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </>
    );
};

            export default DataList;