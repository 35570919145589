import { useEffect, useState } from "react";
import getApiCall from "../../Services/getApiCall";

import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import 'rsuite/Pagination/styles/index.css';


const DataList = () => {
    const [records, setRecords] = useState([])

    useEffect(() => {
        async function getData() {
            let docs = await getApiCall("/admin/cms-list", { }, true);
            if (docs.status) {

                // data binding

                let records = docs.data.map((el, i) => {
                    return <tr>
                        <td scope="row">{ i + 1 }</td>
                        <td>{ el.url }</td>
                        <td>{ el.title }</td>
                        <td>{ el.description }</td>
                        <td className="text-end"><Link to={`/edit-cms/${el._id}`}><button type="button" className="btn btn-light btn-sm btn-icon m-1"><img src="images/edit.png" /></button></Link></td>
                    </tr>
                });

                setRecords(records);
            } else {
                toast.error(docs.message != undefined ? docs.message : "Network error!!");
                setRecords([]);
            }
        }
        getData()

    }, []);

  
    return (
        <>
            <div className="row">
                <div className="col-7 col-sm-8 col-md-8 col-lg-6">
                    <div className="page-header">
                        <h4>CMS Management</h4>
                    </div>
                </div>
                <div className="col-5 col-sm-4 col-md-4 col-lg-6 form-box form-filter text-end">
                    {/* <div className="btn-bg d-inline-block">
                        <Link to="/cms/add" className="btn btn-sumbit btn-primary"> Add Page</Link>
                    </div> */}
                </div>
            </div>

            <div className="table-card">
                <div className="table-reponsive">
                    <table className="table table-white">
                        <thead>
                            <tr className="table-tr-light">
                                <th scope="col" className="text-uppercase">Sr.No </th>
                                <th scope="col" className="text-uppercase">Url</th>
                                <th scope="col" className="text-uppercase">Title</th>
                                <th width="30%" scope="col" className="text-uppercase">DESCRIPTION </th>
                                <th scope="col" className="text-uppercase text-end">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (records.length > 0)
                                ?
                                records
                                :
                                'No result found'
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default DataList;