import React from "react";

function Verify() {
  return (
    <div className="login-wrap">
      <div className="container-fluid">
        <div className="form-box-login">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <div className="login-white-box form-box bg-light py-7">
                <div className="congrulations-img">
                  <img src="images/congrulations-icon.svg" />
                </div>
                <div className="title mb-3 text-center">
                  <h4 className="mb-1">Password Changed Successfully</h4>
                  <p className="mb-0">
                    Your password has been changed successfully.
                    <br />
                    You can login to your account
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Verify;
