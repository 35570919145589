import { useEffect, useState } from "react";
import putApiCall from "../../Services/putApiCall";

import { toast, ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';

import 'rsuite/Pagination/styles/index.css';


const ChangePassword = (props) => {
    const [request, setRequestParms] = useState({});

    const setRequest = (name, e) => {
        setRequestParms({ ...request, [name]: e.target.value })
    }

    const updatePassword = async (e) => {
        e.preventDefault();
        let data = await putApiCall('/admin/change-password', request, true);

        if (data.status) {
            toast.success(data.message);
            sessionStorage.clear();
            window.location.href = '/login';
        }
        else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    return (
        <>
            {/* <ToastContainer /> */}
            <div className="dashboardMain">
                <div className="page-header back-page">
                    <h4><Link className="back-arrow" to="/profile"><img src="images/back-arrow.svg"/></Link>Change Password</h4>
                </div>
                <div className="account_dashboard">
                    <div className="form-box">
                        <form onSubmit={ updatePassword }>
                            <div className="row">
                                <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                    <div className="form-group">
                                        <label>Enter Old Password</label>
                                        <input type="password" name="oldPassword" className="form-ctrl form-control" placeholder="Enter old password" onChange={ (e)=> { setRequest('oldPassword', e)}}/>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                    <div className="form-group">
                                        <label>Enter New Password</label>
                                        <input type="password" name="newPassword" className="form-ctrl form-control" placeholder="Enter new password" onChange={ (e)=> { setRequest('newPassword', e)}}/>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                    <div className="form-group">
                                        <label>Re-Enter New Password</label>
                                        <input type="password" name="confirmPassword" className="form-ctrl form-control" placeholder="Enter confirm password" onChange={ (e)=> { setRequest('confirmPassword', e)}}/>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-center mt-5">

                                <div className="col-12 col-sm-4 col-md-3 col-lg-2">
                                    <button type="submit" className="btn me-2 btn-sumbit btn-primary btn-block">Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </>
    )

}

export default ChangePassword;