import { useEffect, useState } from "react";
import postApiCall from "../../Services/postApiCall";
import putApiCall from "../../Services/putApiCall";
import patchApiCall from "../../Services/patchApiCall";
import deleteApiCall from "../../Services/deleteApiCall";
import getApiCall from "../../Services/getApiCall";

import { toast, ToastContainer } from 'react-toastify';
import { Pagination } from 'rsuite';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'rsuite/Pagination/styles/index.css';


const DataList = (props) => {

    const [records, setRecords] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [totalLength, setTotalLength] = useState(0);
    const [status, setStatus] = useState("");
    const [details, setDetails] = useState({});
    const [request, setRequestParms] = useState({});

    useEffect(() => {
        async function getData() {
            let data = await postApiCall('/admin/inquery', { page, status }, true);
            if (data.status) {
                setPage(data.page);
                setTotalPage(data.total);

                // data binding
                let records = data.data.map((el, i) => {
                    return  <tr>
                                <td scope="row">{ i + 1 }</td>
                                <td>{ el.panel_type }</td>
								<td>{ el.user_type }</td>
								<td>{ el.fullName }</td>
                                <td>{ el.email }</td>
                                <td>{ el.phone }</td>
                                <td>{ el.message }</td>
                                <td>{ moment(el.createdAt).format('DD-MM-YYYY') }</td>
                                <td className="text-end">{
                                    (el.status === 'Open')
                                    ?
                                    <p class="text-end"><a href="javascript:void(0);" className="text-danger" onClick={() => { isActive(el._id) }}>{ el.status }</a></p>
                                    :
                                    <p className="text-success text-end">{ el.status }</p>
                                }</td>
                            </tr>
                });
                setRecords(records);
                setTotalLength(data.total * 10);
            }
            else {
                toast.error(data.message != undefined ? data.message : 'Network error!!');
                setRecords([]);
            }
        }
        getData()

    }, []);

    const getRecords = async (page, status) => {
        let data = await postApiCall('/admin/inquery', { page, status }, true);
        if (data.status) {
            setPage(data.page);
            setTotalPage(data.total);

            // data binding
            let records = data.data.map((el, i) => {
                return  <tr>
                            <td scope="row">{ i + 1 }</td>
							<td>{ el.panel_type }</td>
							<td>{ el.user_type }</td>
                            <td>{ el.fullName }</td>
                            <td>{ el.email }</td>
                            <td>{ el.phone }</td>
                            <td>{ el.message }</td>
                            <td>{ moment(el.createdAt).format('DD-MM-YYYY') }</td>
                            <td>{
                                (el.status === 'Open')
                                ?
                                <p className="text-end"><a href="javascript:void(0);" className="text-danger" onClick={() => { isActive(el._id) }}>{ el.status }</a></p>
                                :
                                <p className="text-end text-success">{ el.status }</p>
                            }</td>
                        </tr>
            });
            setRecords(records);
            setTotalLength(data.total * 10);
        }
        else {
            toast.error(data.message != undefined ? data.message : 'Network error!!');
            setRecords([]);
        }
    }

    // change status

    async function isActive(id) {
        let data = await patchApiCall('/admin/inquery/' + id, {}, true);
        if (data.status) {
            toast.success(data.message);
            getRecords(page, status);
        }
        else {
            toast.error(data.message != undefined ? data.message : 'Network error!!');
        }
    }

    const pagination = async (page) => {
        await getRecords(page)
    }

    const clearFilter = () => {
        window.location.reload(false);
    }

    return (
        <>

         
            <div className="page-header">
                <h4>Complaints Management</h4>
            </div>

            <div className="table-card">
                <div className="table-reponsive">
                    <table className="table table-white">
                        <thead>
                            <tr className="table-tr-light">
                                <th scope="col" className="text-uppercase">Sr.No </th>
                                <th scope="col" className="text-uppercase">Panel Type</th>
								<th scope="col" className="text-uppercase">User Type</th>
								<th scope="col" className="text-uppercase">Name</th>
                                <th scope="col" className="text-uppercase">Email Id</th>
                                <th scope="col" className="text-uppercase">Number</th>
                                <th width="30%" scope="col" className="text-uppercase">DESCRIPTION </th>
                                <th scope="col" className="text-uppercase">Date</th>
                                <th scope="col" className="text-uppercase text-end">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (records.length > 0)
                                ?
                                records
                                :
                                'No result found'
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            <nav aria-label="Page navigation example">
                <Pagination total={totalLength} limit={10} activePage={page} prev last next first onChangePage={pagination} className="pagination justify-content-center navigation-number" />
            </nav>

        </>
    )

}

export default DataList;