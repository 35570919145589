import React from "react";

const VerifyOTP = () => {
  return (
    <>
      <div className="login-wrap  bg-white">
        <div className="container-fluid">
          <div className="form-box-login">
            <div className="row  justify-content-center">
              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                <div className="login-white-box form-box  otp-box bg-light">
                  <div className="back-page border-0">
                    <h3 className="mb-0">
                      <a className="back-arrow" href="javascript:history.back()">
                        <img src="images/ArrowBack.svg" />
                      </a>{" "}
                      OTP Verification
                    </h3>
                  </div>
                  <div className="title mb-3">
                    <h4 className="mb-1">OTP sent to email id</h4>
                    <p className="mb-0">
                      We sent a 6 digit OTP code to your registered email id.
                    </p>
                  </div>
                  <form action="">
                    <div className="form-group">
                      <input
                        type="number"
                        className="form-ctrl form-control"
                        onkeypress="if(this.value.length==1) return false; return event.keyCode === 8 || event.charCode >= 48 &amp;&amp; event.charCode <= 57;"
                      />
                      <input
                        type="number"
                        className="form-ctrl form-control"
                        onkeypress="if(this.value.length==1) return false; return event.keyCode === 8 || event.charCode >= 48 &amp;&amp; event.charCode <= 57;"
                      />
                      <input
                        type="number"
                        className="form-ctrl form-control"
                        onkeypress="if(this.value.length==1) return false; return event.keyCode === 8 || event.charCode >= 48 &amp;&amp; event.charCode <= 57;"
                      />
                      <input
                        type="number"
                        className="form-ctrl form-control"
                        onkeypress="if(this.value.length==1) return false; return event.keyCode === 8 || event.charCode >= 48 &amp;&amp; event.charCode <= 57;"
                      />
                      <input
                        type="number"
                        className="form-ctrl form-control"
                        onkeypress="if(this.value.length==1) return false; return event.keyCode === 8 || event.charCode >= 48 &amp;&amp; event.charCode <= 57;"
                      />
                      <input
                        type="number"
                        className="form-ctrl form-control"
                        onkeypress="if(this.value.length==1) return false; return event.keyCode === 8 || event.charCode >= 48 &amp;&amp; event.charCode <= 57;"
                      />
                    </div>

                    <div className="SignUp-txt form-group justify-content-start mb-5">
                      <p>
                        If you didn’t the receive the code. <br />
                        <a className="text-warning" href="#">
                          <strong>Resend OTP</strong>{" "}
                        </a>{" "}
                        in 10 sec
                      </p>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-block btn-sumbit btn-lg btn-warning bg-gradient-warning"
                    >
                      Verify
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyOTP;
