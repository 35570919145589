import { useEffect, useState } from "react";
import getApiCall from "../../Services/getApiCall";
import putApiCall from "../../Services/putApiCall";

import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import 'rsuite/Pagination/styles/index.css';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build';


const DataList = (props) => {
    const [record, setRecord] = useState([])
    const [request, setRequest] = useState({})
    const id = props.match.params.id;

    useEffect(() => {
        
        getData()

    }, []);

    const getData = async () => {
        let docs = await getApiCall("/admin/cms/" + id, {}, true);
        if (docs.status) {
            setRecord(docs.data);
        } else {
            toast.error(docs.message != undefined ? docs.message : "Network error!!")
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let data = await putApiCall("/admin/cms/" + id, request, true);

        if(data.status) {
            toast.success(data.message)
        }
        else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    const handleChange = (e) => {
        setRequest({ 'description': e.target.value });
    }


    return (
        <>
            <ToastContainer/>
            <div className="page-header back-page">
                <h4><Link to="/cms" className="back-arrow"><img src="/images/back-arrow.svg" /></Link> Edit Page</h4>
            </div>
            <div className="account_dashboard">
                <div className="form-box">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label>Title</label>
                                    <input type="text" className="form-control form-ctrl" placeholder="Enter" value={ record.title }/>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label>Url</label>
                                    <input type="text" className="form-control form-ctrl" placeholder="Enter" value={ record.url }/>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <label>Description <sup className="text-danger">*</sup></label>
                            {/*<textarea rows="6" className="form-control form-ctrl" placeholder="Enter" onChange={ (e)=> { handleChange(e) }} defaultValue={ record.description }></textarea>*/}
							<CKEditor 
							   editor = { ClassicEditor }
							   data = { record.description }
							   onChange = { (event, editor) => { let data = editor.getData(); setRequest({ 'description': data }); }}
							/>
                        </div>
						
                        <div className="row justify-content-center mt-5">
                            <div className="col-12 col-sm-4 col-md-3 col-lg-2">
                                <button type="submit" className="btn me-2 btn-sumbit btn-primary btn-block">Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </>
    );
};

            export default DataList;