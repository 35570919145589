import { useEffect, useState } from "react";
import postApiCall from "../../Services/postApiCall";
import putApiCall from "../../Services/putApiCall";
import patchApiCall from "../../Services/patchApiCall";
import getApiCall from "../../Services/getApiCall";

import { toast, ToastContainer } from 'react-toastify';
import { Pagination } from 'rsuite';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build';
import Parse from 'html-react-parser';

import 'rsuite/Pagination/styles/index.css';

let defaultOptions = <>
    <option id="0" value="0">Select one</option>
    <option id="1" value="Weekly">Weekly</option>
    <option id="2" value="Monthly">Monthly</option>
    <option id="3" value="Quarterly">Quarterly</option>
    <option id="4" value="Half Yearly">Half Yearly</option>
    <option id="5" value="Yearly">Yearly</option>
</>

let defaultUserOptions = <>
    <option id="0" value="0">Select one</option>
    <option id="1" value="JobSeeker">Job Seeker</option>
    <option id="2" value="Employer">Employer</option>
</>

const DataList = (props) => {

    const [records, setRecords] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [totalLength, setTotalLength] = useState(0);
    const [status, setStatus] = useState(true);
    const [details, setDetails] = useState({});
    const [request, setRequestParms] = useState({});
    let [options, setOptions] = useState(defaultOptions);
    let [userOptions, setUserOptions] = useState(defaultUserOptions);

    useEffect(() => {
        async function getData(status) {
            let data = await postApiCall('/admin/plan/get-list', { page, status }, true);
            if (data.status) {
                setPage(data.page);
                setTotalPage(data.total);

                // data binding
                let records = data.data.map((el, i) => {
                    return  <tr>
                                <td scope="row">{ i + 1 }</td>
                                <td>{ el.name }</td>
                                <td>{ el.type }</td>
                                <td>{ el.price }</td>
                                <td>{ Parse(el.description) }</td>
                                <td>{ el.userType }</td>
                                <td>{ el.noOfJobs }</td>

                                <td className="text-end">
                                    {
                                        (el.status)
                                            ?
                                            <a href="#" className="btn btn-sm btn-green btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check-white.svg" /></a>
                                            :
                                            <a href="#" className="btn btn-sm btn-light btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check.svg" /></a>
                                    }
                                    <a href="#planModal" data-bs-toggle="modal" className="btn btn-light btn-sm btn-icon m-1"><img src="images/edit.png" onClick={ ()=> { getDetails(el._id) } }/></a>
                                    <button type="button" className="btn btn-light btn-sm btn-icon  m-1"><img src="images/trash.svg" onClick={ ()=> { isDeleted(el._id) } }/></button>
                                </td>
                            </tr>
                });
                setRecords(records);
                setTotalLength(data.total * 10);
            }
            else {
                toast.error(data.message != undefined ? data.message : 'Network error!!');
                setRecords([]);
            }
        }
        getData(status)

    }, [status]);

    const getRecords = async (page, status) => {
        let data = await postApiCall('/admin/plan/get-list', { page, status }, true);
        if (data.status) {
            setPage(data.page);
            setTotalPage(data.total);

            // data binding
            let records = data.data.map((el, i) => {
                return  <tr>
                            <td scope="row">{ i + 1 }</td>
                            <td>{ el.name }</td>
                            <td>{ el.type }</td>
                            <td>{ el.price }</td>
                            <td>{ Parse(el.description) }</td>
                            <td>{ el.userType }</td>
                            <td>{ el.noOfJobs }</td>
                            <td className="text-end">
                                {
                                    (el.status)
                                        ?
                                        <a href="#" className="btn btn-sm btn-green btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check-white.svg" /></a>
                                        :
                                        <a href="#" className="btn btn-sm btn-light btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check.svg" /></a>
                                }
                                <a href="#planModal" data-bs-toggle="modal" className="btn btn-light btn-sm btn-icon m-1"><img src="images/edit.png" onClick={ ()=> { getDetails(el._id) } }/></a>
                                <button type="button" className="btn btn-light btn-sm btn-icon  m-1"><img src="images/trash.svg" onClick={ ()=> { isDeleted(el._id) } }/></button>
                            </td>
                        </tr>
            });
            setRecords(records);
            setTotalLength(data.total * 10);
        }
        else {
            toast.error(data.message != undefined ? data.message : 'Network error!!');
            setRecords([]);
        }
    }

    // change status

    async function isActive(id) {
        let data = await patchApiCall('/admin/plan/change-status/' + id, {}, true);
        if (data.status) {
            toast.success(data.message);
            getRecords(page, status);
        }
        else {
            toast.error(data.message != undefined ? data.message : 'Network error!!');
        }
    }

    // call delete function 

    async function isDeleted(id) {
        if (window.confirm("Are you sure you want to delete this plan?")) {
            let data = await patchApiCall("/admin/plan/delete/" + id, {}, true);
            if (data.status) {
                toast.warning(data.message)
                getRecords(page, status)
            } else {
                toast.error(data.message != undefined ? data.message : "Network error!!")
            }
        }
    }

    const setRequest = (name, e) => {
        setRequestParms({ ...request, [name]: e.target.value })
    }

    const createNewContent = async (e) => {
        e.preventDefault();
        let data = {};
        let requestData = {
            'name': (request.name) ? request.name : details.name,
            'type': (request.type) ? request.type : details.type, 
            'price': (request.price) ? request.price : details.price, 
            'description': (request.description) ? request.description : details.description,
            'userType': (request.userType) ? request.userType : details.userType,
            'noOfJobs': (request.noOfJobs) ? request.noOfJobs : details.noOfJobs
        }
        if(details.name) {
            data = await putApiCall(`/admin/plan/edit/${details._id}`, requestData, true);
        }
        else {
            data = await postApiCall('/admin/plan/create', requestData, true);
        }

        if(data.status) {
            toast.success(data.message);
            window.location.reload(false);
        }
        else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    async function getDetails(id) {
        let data = await getApiCall("/admin/plan/details/" + id, {}, true);
        if (data.status) {
            toast.success(data.message)
            setDetails(data.data);
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    // get job details by id

    const pagination = async (page) => {
        await getRecords(page)
    }

    const clearFormData = () => {
        setDetails({});
    }


    return (
        <>
			<ToastContainer/>
            <div className="page-header">
                <h4>Membership Plan Management</h4>
            </div>

            <div className="form-box form-filter">
                <div className="row">
                    <div className="col-12 col-sm-4 col-md-4 col-lg-3">
                        <ul className="nav nav-pills nav-justified tabs-nav bg-white">
                            <li className="nav-item">
                                <a className="nav-link active" data-bs-toggle="tab" aria-current="page" href="#ActiveTab" onClick={ ()=> { setStatus(true) }}>Active</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="tab" href="#InactiveTab" onClick={ ()=> { setStatus(false) }}>Inactive</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-sm-8 col-md-8 col-lg-9 text-end">
                        <div className="btn-bg d-inline-block">
                            <a href="#planModal" data-bs-toggle="modal" className="btn btn-sumbit btn-success" onClick={ clearFormData }> Add New Plan</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="table-card">
                <div className="table-reponsive">
                    <table className="table table-white">
                        <thead>
                            <tr className="table-tr-light">
                                <th scope="col" className="text-uppercase">Sr.No </th>
                                <th scope="col" className="text-uppercase">Package name</th>
                                <th scope="col" className="text-uppercase">Package Type</th>
                                <th scope="col" className="text-uppercase">Price</th>
                                <th width="35%" scope="col" className="text-uppercase">Description</th>
                                <th scope="col" className="text-uppercase">User Type</th>
                                <th scope="col" className="text-uppercase">No of Post Jobs</th>
                                <th width="16%" scope="col" className="text-uppercase text-end">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            { (records.length > 0) ? records : 'No result found' }
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Job Details modal End */}

            <nav aria-label="Page navigation example">
                <Pagination total={totalLength} limit={10} activePage={page} prev last next first onChangePage={pagination} className="pagination justify-content-center navigation-number" />
            </nav>

            {/* Banner modal Start */}

            <div className="modal fade" id="planModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center border-0 text-center">
                            <h4 className="modal-title w-100">{ (details.name) ? 'Edit' : 'Add' } Membership Plan</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-box">
                                <form onSubmit={ createNewContent }>
                                    <div className="login-white-box shadow-none pt-1">
                                        <div className="form-group">
                                            <label>Package Name*</label>
                                            <input type="text" className="form-control form-ctrl" placeholder="Enter" defaultValue={ details.name } onChange={ (e)=> { setRequest('name', e) } }/>
                                        </div>
                                        <div className="form-group">
                                            <label>Package Type*</label>
                                            <select className="form-control form-ctrl" onChange={ (e)=> { setRequest('type', e)} }>
                                                {
                                                    (details.type)
                                                    ?
                                                        (details.type === 'Weekly') 
                                                        ?

                                                            options = <>
                                                                <option id="1" value="Weekly" selected="selected">Weekly</option>
                                                                <option id="2" value="Monthly">Monthly</option>
                                                                <option id="3" value="Quarterly">Quarterly</option>
                                                                <option id="4" value="Half Yearly">Half Yearly</option>
                                                                <option id="5" value="Yearly">Yearly</option>
                                                            </>
                                                        :
                                                        (details.type === 'Monthly')
                                                        ?
                                                            options = <>
                                                                <option id="1" value="Weekly">Weekly</option>
                                                                <option id="2" value="Monthly" selected="selected">Monthly</option>
                                                                <option id="3" value="Quarterly">Quarterly</option>
                                                                <option id="4" value="Half Yearly">Half Yearly</option>
                                                                <option id="5" value="Yearly">Yearly</option>
                                                            </>
                                                        :
                                                        (details.type === 'Quarterly')
                                                        ?
                                                            options = <>
                                                                <option id="1" value="Weekly">Weekly</option>
                                                                <option id="2" value="Monthly">Monthly</option>
                                                                <option id="3" value="Quarterly" selected="selected">Quarterly</option>
                                                                <option id="4" value="Half Yearly">Half Yearly</option>
                                                                <option id="3" value="Yearly">Yearly</option>
                                                            </>
                                                        :
                                                        (details.type === 'Half Yearly')
                                                        ?
                                                            options = <>
                                                                <option id="1" value="Weekly">Weekly</option>
                                                                <option id="2" value="Monthly">Monthly</option>
                                                                <option id="3" value="Quarterly">Quarterly</option>
                                                                <option id="4" value="Half Yearly" selected="selected">Half Yearly</option>
                                                                <option id="3" value="Yearly">Yearly</option>
                                                            </>
                                                        :
                                                        (details.type === 'Yearly')
                                                        ?
                                                            options = <>
                                                                <option id="1" value="Weekly">Weekly</option>
                                                                <option id="2" value="Monthly">Monthly</option>
                                                                <option id="3" value="Quarterly">Quarterly</option>
                                                                <option id="4" value="Half Yearly">Half Yearly</option>
                                                                <option id="3" value="Yearly" selected="selected">Yearly</option>
                                                            </>
                                                        :
                                                           null
                                                    :
                                                    options
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>User Type*</label>
                                            <select className="form-control form-ctrl" onChange={ (e)=> { setRequest('userType', e)} }>
                                                {
                                                    (details.userType)
                                                    ?
                                                        (details.userType === 'JobSeeker') 
                                                        ?

                                                            userOptions = <>
                                                                <option id="1" value="JobSeeker" selected="selected">Job Seeker</option>
                                                                <option id="2" value="Employer">Employer</option>
                                                            </>
                                                        :
                                                        (details.userType === 'Employer')
                                                        ?
                                                            userOptions = <>
                                                                <option id="1" value="JobSeeker">Job Seeker</option>
                                                                <option id="2" value="Employer" selected="selected">Employer</option>
                                                            </>
                                                        :
                                                        null
                                                    :
                                                    userOptions
                                                }
                                            </select>
                                        </div>
                                        {
                                            (request.userType === 'Employer' || details.userType === 'Employer')
                                            ?
                                                <div className="form-group">
                                                    <label>Number of Post Jobs</label>
                                                    <input type="number" className="form-control form-ctrl" placeholder="Enter" defaultValue={ details.noOfJobs } onChange={ (e)=> { setRequest('noOfJobs', e) } }/>
                                                </div>
                                            :
                                            null
                                        }
                                        <div className="form-group">
                                            <label>Price*</label>
                                            <input type="text" className="form-control form-ctrl" placeholder="Enter" defaultValue={ details.price } onChange={ (e)=> { setRequest('price', e) } }/>
                                        </div>
                                        <div className="form-group">
                                            <label>Description*</label>

                                            <CKEditor 
                                               editor = { ClassicEditor }
                                               data = { details.description }
                                               onChange = { (event, editor) => { let data = editor.getData(); setRequestParms({ ...request, 'description': data }) }}
                                            />

                                        </div>
                                        <div className="btn-width m-auto">
                                            <button type="submit" className="btn btn-block btn-normal btn-primary">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Banner modal End */}

        </>
    )

}

export default DataList;