import { useEffect, useState } from "react";
import postApiCall from "../../Services/postApiCall";
import patchApiCall from "../../Services/patchApiCall";
import deleteApiCall from "../../Services/deleteApiCall";
import getApiCall from "../../Services/getApiCall";

import { toast, ToastContainer } from "react-toastify";
import { Pagination, Input } from 'rsuite';
import { Link } from "react-router-dom";
import moment from 'moment';
import 'rsuite/Pagination/styles/index.css';


const DataList = () => {
    const [records, setRecords] = useState([])
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [totalLength, setTotalLength] = useState(0)
    const [details, setDetails] = useState({})
    const [request, setRequestParms] = useState({})
    const [companyName, setCompanyName] = useState('');
	const [companyDescription, setCompanyDescription] = useState('');
	const [companyImage, setCompanyImage] = useState('');

    useEffect(() => {
		async function getData() {
            let docs = await postApiCall("/admin/master/company", {  }, true);
            if (docs.status) {
                setPage(docs.page)
                setTotalPage(docs.total)

                // data binding

                let records = docs.data.map((el, i) => {
                    return <tr>
                                <td scope="row">{i + 1}</td>
                                <td>{ el.companyName }</td>
                                <td><img class="me-2 td-user" src={ el.companyImage } /></td>
								<td className="">

								{
									(el.feature)
									?
									<a href="#" className="btn btn-sm btn-green btn-icon m-1" onClick={() => { feature(el._id) }}><img src="images/check-white.svg" /></a>
									:
									<a href="#" className="btn btn-sm btn-light btn-icon m-1" onClick={() => { feature(el._id) }}><img src="images/check.svg" /></a>
								}

								</td>
                                <td className="">

                                {
                                    (el.isActive)
                                    ?
                                    <a href="#" className="btn btn-sm btn-green btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check-white.svg" /></a>
                                    :
                                    <a href="#" className="btn btn-sm btn-light btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check.svg" /></a>
                                }

                                </td>
                                <td class="text-end">
                                    <a href="#AddCompanyModal" data-bs-toggle="modal" class="btn btn-light btn-sm btn-icon m-1" onClick={() => { getDetails(el._id) }}><img src="images/edit.png" /></a>
                                    {/* <button type="button" class="btn btn-light btn-sm btn-icon  m-1" onClick={() => { isDeleted(el._id) }}><img src="images/trash.svg" /></button> */}
                                </td>
                            </tr>
                });

                setRecords(records);

                setTotalLength(docs.total * 10)
            } else {
                toast.error(docs.message != undefined ? docs.message : "Network error!!")
                setRecords([]);
            }
        }
        getData()

    }, []);

    const getRecords = async (page) => {

        let docs = await postApiCall("/admin/master/company", { page }, true);

        if (docs.status) {
            setPage(docs.page)
            setTotalPage(docs.total)
            setTotalLength(docs.total * 10)

            // data binding

            let records = docs.data.map((el, i) => {
                return  <tr>
                            <td scope="row">{i + 1}</td>
                            <td>{ el.companyName }</td>
                            <td><img class="me-2 td-user" src={ el.companyImage } /></td>
							<td className="">

                            {
                                (el.feature)
                                ?
                                <a href="#" className="btn btn-sm btn-green btn-icon m-1" onClick={() => { feature(el._id) }}><img src="images/check-white.svg" /></a>
                                :
                                <a href="#" className="btn btn-sm btn-light btn-icon m-1" onClick={() => { feature(el._id) }}><img src="images/check.svg" /></a>
                            }

                            </td>
                            <td className="">

                            {
                                (el.isActive)
                                ?
                                <a href="#" className="btn btn-sm btn-green btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check-white.svg" /></a>
                                :
                                <a href="#" className="btn btn-sm btn-light btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check.svg" /></a>
                            }

                            </td>
                            <td class="text-end">
                                <a href="#AddCompanyModal" data-bs-toggle="modal" class="btn btn-light btn-sm btn-icon m-1" onClick={() => { getDetails(el._id) }}><img src="images/edit.png" /></a>
                                {/* <button type="button" class="btn btn-light btn-sm btn-icon  m-1" onClick={() => { isDeleted(el._id) }}><img src="images/trash.svg" /></button> */}
                            </td>
                        </tr>
            });

            setRecords(records);
        } else {
            toast.error(docs.message != undefined ? docs.message : "Network error!!");
            setRecords([]);
        }
    }

    // call change status function

    async function isActive(id) {
        let data = await patchApiCall("/admin/master/company/" + id, {}, true);
        if (data.status) {
            toast.warning(data.message)
            getRecords(page)
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }
	
	async function feature(id) {
        let data = await patchApiCall("/admin/master/company/feature/" + id, {}, true);
        if (data.status) {
            toast.warning(data.message)
            getRecords(page)
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    // call delete function 

    async function isDeleted(id) {
        if (window.confirm("Are you sure you want to delete this company?")) {
            let data = await patchApiCall("/admin/master/company/" + id, {}, true);
            if (data.status) {
                toast.warning(data.message)
                getRecords(page)
            } else {
                toast.error(data.message != undefined ? data.message : "Network error!!")
            }
        }
    }

    const setRequest = (name, e) => {
        setRequestParms({ ...request, [name]: e.target.value })
    }
    
    const createNewContent = async (e) => {
        e.preventDefault();
        let data = {};
		
		if(details.companyName) {
            let metaData = {
                'id': details._id,
                'companyName': (request.companyName) ? request.companyName : details.companyName,
				'companyDescription': (request.companyDescription) ? request.companyDescription : details.companyDescription,
                'companyImage': (request.companyImage) ? request.companyImage : details.companyImage
            }

            data = await patchApiCall("/admin/master/company/edit", metaData, true);
        }
        else {
            data = await postApiCall("/admin/master/company/create", request, true);
        }
        
        if (data.status) {
            toast.success(data.message)
            window.location.reload(false);
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    async function uploadImage(event) {
        let image = event.target.files[0];
        const formData = new FormData;
        formData.append('image', image, image.name);
        let data = await postApiCall("/admin/common/upload", formData, true);
        if (data.status) {
            setRequestParms({ ...request, ['companyImage']: data.data.location  })
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    async function getDetails(id) {
        let data = await getApiCall("/admin/master/company/" + id, {}, true);
        if (data.status) {
            toast.warning(data.message)
            setDetails(data.data)
            setCompanyName(data.data.companyName);
			setCompanyDescription(data.data.companyDescription);
			setCompanyImage(data.data.companyImage);
			
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    // get job details by id

    const pagination = async (page) => {
        await getRecords(page)
    }

    const clearDetails = () => {
        setDetails({})
		setCompanyName('');
		setCompanyDescription('');
		setCompanyImage('');
    }

    return (
        <>
            <ToastContainer/>
			<div class="row">
                <div class="col-7 col-sm-8 col-md-8 col-lg-8">
                    <div class="page-header">
                        <h4>Company</h4>
                    </div>
                </div>

                <div class="col-5 col-sm-4 col-md-4 col-lg-4 form-box form-filter text-end">
                    <div class="btn-bg d-inline-block">
                        <a href="#AddCompanyModal" data-bs-toggle="modal" class="btn btn-sumbit btn-success" onClick={ clearDetails }> Add New</a>
                    </div>
                </div>
            </div>
            <div class="table-card">
                <div class="table-reponsive">
                    <table class="table table-white">
                        <thead>
                            <tr class="table-tr-light">
                                <th scope="col" class="text-uppercase">Sr.No </th>
                                <th scope="col" class="text-uppercase">Company Name</th>
                                <th scope="col" class="text-uppercase">Company Image</th>
                                <th scope="col" class="text-uppercase">Featured</th>
								<th scope="col" class="text-uppercase">Status</th>
                                <th width="15%" scope="col" class="text-uppercase text-end">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            { (records.length > 0) ? records : 'No result found' }
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Add Company modal Start */}

            <div class="modal fade" id="AddCompanyModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header justify-content-center border-0 text-center">
                            <h4 class="modal-title w-100">{ (details.companyName) ? 'Edit' : 'Add' } Company</h4>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="form-box">
                                <form onSubmit={ createNewContent }>
                                    <div class="login-white-box shadow-none pt-1">
                                        <div class="form-group">
                                            <label>Company Name*</label>
                                            {/* <Input placeholder="Company Name" type="text" className="form-control form-ctrl" value={ companyName } onChange={(evt) => setRequest("companyName", evt)}/> */}
                                            <input type="text" name="companyName" class="form-control form-ctrl" placeholder="Enter" defaultValue={ companyName } onChange={(evt) => setRequest("companyName", evt)}/>
                                        </div>
										<div class="form-group">
                                            <label>Company Description</label>
                                            <input type="text" name="companyDescription" class="form-control form-ctrl" placeholder="Enter" defaultValue={ companyDescription } onChange={(evt) => setRequest("companyDescription", evt)}/>
                                        </div>
                                        <div class="form-group">
                                            <label>Company Image*</label>
                                            <div class="upload-doc-item Upload-plus">
                                                <div class="image-upload">
                                                    <input type="file" name="" id="logo" onChange={ (e) => { uploadImage(e) } }/>
                                                    <label for="logo" class="upload-field" id="file-label">
                                                        <div class="file-thumbnail">
                                                            <img id="file-image-preview" src="images/upload-blue.png" alt=""/><br/>
                                                            <h6 id="filename">Upload </h6>
                                                        </div>
                                                    </label>
                                                </div>
												{(companyImage)?<> <img src={companyImage} /></>:"" }
                                            </div>
                                        </div>
                                        <div class="btn-width m-auto">
                                            <button type="submit" class="btn btn-sumbit btn-primary btn-block">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* Add Company modal End */}

                <nav aria-label="Page navigation example">
                    <Pagination total={totalLength} limit={10} activePage={page} prev last next first onChangePage={pagination} className="pagination justify-content-center navigation-number" />
                </nav>
            </>
            );
};

export default DataList;