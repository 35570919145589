import { useEffect, useState } from "react";
import postApiCall from "../../Services/postApiCall";
import patchApiCall from "../../Services/patchApiCall";
import deleteApiCall from "../../Services/deleteApiCall";
import getApiCall from "../../Services/getApiCall";

import { toast, ToastContainer } from "react-toastify";
import { Pagination } from 'rsuite';
import { Link } from "react-router-dom";
import moment from 'moment';
import 'rsuite/Pagination/styles/index.css';


const DataList = () => {
    const [records, setRecords] = useState([])
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [totalLength, setTotalLength] = useState(0)
    const [Details, setDetails] = useState({})


    useEffect(() => {
        async function getData() {
            let docs = await getApiCall("/admin/common/all-states", { }, true);
            if (docs.status) {
                setPage(docs.page)
                setTotalPage(docs.total)

                // data binding

                let records = docs.data.map((el, i) => {
                    return <tr>
                        <td scope="row">{i + 1}</td>
                        <td>{el.name}</td>
                        <td>{el.isoCode}</td>
                        <td>{el.countryCode}</td>
                        <td className="text-success">{el.status}</td>
                    </tr>
                });

                setRecords(records);

                setTotalLength(docs.total * 10)
            } else {
                toast.error(docs.message != undefined ? docs.message : "Network error!!")
                setRecords([])
            }
        }
        getData()

    }, []);

    // const getRecords = async (page) => {

    //     let docs = await postApiCall("/admin/common/get-states", { page }, true);

    //     if (docs.status) {
    //         setPage(docs.page)
    //         setTotalPage(docs.total)
    //         setTotalLength(docs.total * 10)

    //         // data binding

    //         let records = docs.data.map((el, i) => {
    //             return <tr>
    //                 <td scope="row">{i + 1}</td>
    //                 <td>{el.name}</td>
    //                 <td>{el.isoCode}</td>
    //                 <td>{el.countryCode}</td>
    //                 <td>
    //                     {
    //                         (el.status === 'ACTIVE')
    //                             ?
    //                             <a href="#" className="btn btn-sm btn-green btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check-white.svg" /></a>
    //                             :
    //                             <a href="#" className="btn btn-sm btn-light btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check.svg" /></a>
    //                     }
    //                 </td>
    //                 <td className="text-end">
    //                     <a href="#AddCountryModal" data-bs-toggle="modal" className="btn btn-sm btn-light btn-icon m-1" onClick={() => { getDetails(el._id) }}><img src="images/eye.svg" /></a>
    //                     <button type="button" className="btn btn-danger btn-sm btn-icon  m-1" onClick={() => { isDeleted(el._id) }}><img src="images/cross-white-x.svg" /></button>
    //                 </td>
    //             </tr>
    //         });

    //         setRecords(records);
    //     } else {
    //         toast.error(docs.message != undefined ? docs.message : "Network error!!")
    //     }
    // }

    // call change status function

    // async function isActive(id) {
    //     let data = await patchApiCall("/admin/common/state" + id, {}, true);
    //     if (data.status) {
    //         toast.warning(data.message)
    //         getRecords(page)
    //     } else {
    //         toast.error(data.message != undefined ? data.message : "Network error!!")
    //     }
    // }

    // call delete function 

    // async function isDeleted(id) {
    //     if (window.confirm("Are you sure you want to delete this state?")) {
    //         let data = await deleteApiCall("/admin/common/state/" + id, {}, true);
    //         if (data.status) {
    //             toast.warning(data.message)
    //             getRecords(page)
    //         } else {
    //             toast.error(data.message != undefined ? data.message : "Network error!!")
    //         }
    //     }
    // }

    // async function getDetails(id) {
    //     let data = await getApiCall("/admin/common/state/" + id, {}, true);
    //     if (data.status) {
    //         toast.warning(data.message)
    //         setDetails(data.data)
    //     } else {
    //         toast.error(data.message != undefined ? data.message : "Network error!!")
    //     }
    // }

    // get job details by id

    // const pagination = async (page) => {
    //     await getRecords(page)
    // }

    // const filterData = async (type) => {
    //     await getRecords(0);
    // }


    return (
        <>
            <div className="row">
                <div className="col-7 col-sm-8 col-md-8 col-lg-8">
                    <div className="page-header">
                        <h4>State</h4>
                    </div>
                </div>

                {/* <div className="col-5 col-sm-4 col-md-4 col-lg-4 form-box form-filter text-end">
                    <div className="btn-bg d-inline-block">
                        <a href="#AddCountryModal" data-bs-toggle="modal" className="btn btn-sumbit btn-success"> Add Country</a>
                    </div>
                </div> */}
            </div>
            <div className="table-card">
                <div className="table-reponsive">
                    <table className="table table-white">
                        <thead>
                            <tr className="table-tr-light">
                                <th scope="col" className="text-uppercase">Sr.No </th>
                                <th scope="col" className="text-uppercase">Name</th>
                                <th scope="col" className="text-uppercase">ISO Code</th>
                                <th scope="col" className="text-uppercase">Country Code</th>
                                <th scope="col" className="text-uppercase">Status</th>
                                {/* <th width="15%" scope="col" className="text-uppercase text-end">Action</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            { (records.length > 0) ? records : 'No result found' }
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Add and Update country modal Start */}

            <div className="modal fade" id="AddCountryModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center border-0 text-center">
                            <h4 className="modal-title w-100">Add Country</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-box">
                                <form action="country.php">
                                    <div className="login-white-box shadow-none pt-1">
                                        <div className="form-group">
                                            <label>Country Name</label>
                                            <input type="text" name="" className="form-control form-ctrl" placeholder="Enter" />
                                        </div>

                                        <div className="btn-width m-auto">
                                            <button type="submit" className="btn btn-sumbit btn-primary btn-block">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* Add Update Job Details modal End */}

            {/* <nav aria-label="Page navigation example">
                <Pagination total={totalLength} limit={10} activePage={page} prev last next first onChangePage={pagination} className="pagination justify-content-center navigation-number" />
            </nav> */}
        </>
    );
};

export default DataList;