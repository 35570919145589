// import package and components
import React, {Component, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import IsAuthenticat from './IsAuthenticat';

// Auth
import Login from "./Pages/auth/Login";
import PwdForgot from "./Pages/auth/PwdForgot"
import ResetPassword from './Pages/auth/ResetPassword'
import Verify from './Pages/auth/Verify';
import VerifyOTP from './Pages/auth/VerifyOTP';

// ADMIN

// common
import Dashboard from './Pages/Dashboard';
import EmployerMGTList from './Pages/EmployerMGT/DataList';
import ViewJobHistory from './Pages/EmployerMGT/ViewJobHistory';
import JobMGTList from './Pages/JobMGT/DataList';
import JobSeekerMGT from './Pages/JobSeekerMGT/DataList';
import ViewAppliedCondidate from './Pages/JobMGT/ViewAppliedCondidate';

import AdminAccess from "./AdminAccess";
import Layout from "./Layout";

import SiteInfo from "./Pages/SiteInformation";
import Newsletter from "./Pages/Newsletter/NewsletterListing";
import CmsListing from "./Pages/cms/CmsListing";
import CmsEdit from "./Pages/cms/CmsEdit";
import Languages from "./Pages/Masters/Languages";
import Countries from "./Pages/Masters/Countries";
import States from "./Pages/Masters/States";
import Cities from './Pages/Masters/Cities';
import Blogs from './Pages/Masters/Blogs';
import Companies from './Pages/Masters/Companies';
import Industries from './Pages/Masters/Industries';
import Department from './Pages/Masters/Departments';
import SubDepartment from './Pages/Masters/SubDepartments';
import RoleCategories from './Pages/Masters/RoleCategories';
import Roles from './Pages/Masters/Roles';
import Banner from './Pages/BannerMGT/DataList';
import Inqueries from './Pages/ComplaintMGT/DataList';
import ReviewRating from './Pages/Review/DataList';
import MembershipPlan from './Pages/MembershipPlanMGT/DataList';
import LadgerManagement from './Pages/LadgerMGT/DataList';

import ChangePassword from './Pages/Profile/ChangePassword';
import Profile from './Pages/Profile/Profile';
import EditProfile from './Pages/Profile/EditProfile';
import NotificationManagement from './Pages/Notification/DataList';


class App extends Component {


    render() {
        return (
            <Router>
                <Switch>
                    <Route exact strict path="/" component={Login}/>
                    <Route path="/login" component={Login}/>
                    <Route path="/pwd-forgot" component={PwdForgot}/>
                    <Route path="/reset-forgot" component={ResetPassword}/>
                    <Route path="/verify" component={Verify}/>
                    <Route path="/verify-otp" component={VerifyOTP}/>
                    <Route render={() => (IsAuthenticat() === true) ? <DefaultContainer/> : <Login />}/>
                    <Route render={() => <h1>404: page not found</h1>}/>
                </Switch>
            </Router>

        )
    }
}


//To be change in to default router
const DefaultContainer = (props) => 
    (
    <div class="main">
        <Layout>
            <div>
                <div class="dashboardMain">
                    <AdminAccess exact strict path="/cms" component={CmsListing}/>
                    <AdminAccess exact strict path="/edit-cms/:id" component={CmsEdit}/>

                    {/* ARBEIT DASHBOARD ROUTES FOR ADMIN */}

                    <AdminAccess exact strict path="/dashboard" component={Dashboard}/>
                    <AdminAccess exact strict path="/employer-management" component={EmployerMGTList}/>
                    <AdminAccess exact strict path="/view-job-history/:id" component={ViewJobHistory}/>
                    <AdminAccess exact strict path="/job-management" component={JobMGTList}/>
                    <AdminAccess exact strict path="/job-seeker-management" component={JobSeekerMGT}/>
                    <AdminAccess exact strict path="/view-applied-condidates/:id" component={ViewAppliedCondidate}/>
					<AdminAccess exact strict path="/language-management" component={ Languages }/>
                    <AdminAccess exact strict path="/country-management" component={ Countries }/>
                    <AdminAccess exact strict path="/state-management" component={ States }/>
                    <AdminAccess exact strict path="/city-management" component={ Cities }/>
                    <AdminAccess exact strict path="/blog-management" component={ Blogs }/>
					<AdminAccess exact strict path="/company-management" component={ Companies }/>
                    <AdminAccess exact strict path="/industry-management" component={ Industries }/>
                    <AdminAccess exact strict path="/department-management" component={ Department }/>
                    <AdminAccess exact strict path="/role-category-management" component={ RoleCategories }/>
                    <AdminAccess exact strict path="/roles-management" component={ Roles }/>
                    <AdminAccess exact strict path="/banner-management" component={ Banner }/>
                    <AdminAccess exact strict path="/complaint-management" component={ Inqueries }/>
                    <AdminAccess exact strict path="/rating-review-management" component={ ReviewRating }/>
                    <AdminAccess exact strict path="/membership-plan-management" component={ MembershipPlan }/>
                    <AdminAccess exact strict path="/ledger-management" component={ LadgerManagement }/>
                    <AdminAccess exact strict path="/profile" component={ Profile }/>
                    <AdminAccess exact strict path="/change-password" component={ ChangePassword }/>
                    <AdminAccess exact strict path="/edit-profile" component={ EditProfile }/>
                    <AdminAccess exact strict path="/notification-management" component={ NotificationManagement }/>
					<AdminAccess exact strict path="/newsletter" component={ Newsletter }/>
					<AdminAccess exact strict path="/site-info" component={ SiteInfo }/>
					<AdminAccess exact strict path="/subdepartment-management" component={ SubDepartment }/>

                    {/* ARBEIT DASHBOARD ROUTES FOR Business */}
                </div>
            </div>
        </Layout>
    </div>
)


export default App;