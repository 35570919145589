import { useEffect, useState } from "react";
import postApiCall from "../../Services/postApiCall";
import getApiCall from "../../Services/getApiCall";

import { toast, ToastContainer } from 'react-toastify';
import { Pagination } from 'rsuite';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Datepicker from 'react-datepicker';
import Parse from 'html-react-parser';

import 'rsuite/Pagination/styles/index.css';
import "react-datepicker/dist/react-datepicker.css";


const DataList = (props) => {

    const [records, setRecords] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [totalLength, setTotalLength] = useState(0);
    const [details, setDetails] = useState({});
    const [userType, setUserType] = useState("JobSeeker");
    const [searchKey, setSearchKey] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date);


    useEffect(() => {
        async function getData() {
            let data = await postApiCall('/admin/plan/getOrderList', { page, userType }, true);
            if (data.status) {
                setPage(data.page);
                setTotalPage(data.total);

                // data binding
                let records = data.data.map((el, i) => {
                    return  <tr>
                                <td scope="row">{ i + 1 }</td>
                                <td>{ (el.userType === 'JobSeeker') ? el.fullName : el.companyName }</td>
                                <td>{ el.name }</td>
                                <td>{ el.type }</td>
                                <td className="text-success">{ `₹ ${ el.price }` }</td>
                                <td>{ moment(el.createdAt).format('DD-MM-YYYY') }</td>
                                <td>{ moment(el.endDate).format('DD-MM-YYYY') }</td>
								<td>{ el.orderId }</td>
                                <td>{ (el.status === 1) ? 'Pending' : 'Success' }</td>
                                <td>
                                   <a href="#LedgerDetailsModal" data-bs-toggle="modal" className="btn btn-sm btn-light btn-icon m-1" onClick={() => { getLedgerDetails(el) }}><img src="images/eye.svg"/></a>
                                </td>
                            </tr>
                });
                setRecords(records);
                setTotalLength(data.total * 10);
            }
            else {
                toast.error(data.message != undefined ? data.message : 'Network error!!');
                setRecords([]);
            }
        }
        getData()

    }, []);

    const getRecords = async (metaData) => {
        let data = await postApiCall('/admin/plan/getOrderList', metaData, true);
        if (data.status) {
            setPage(data.page);
            setTotalPage(data.total);
            setUserType(metaData.userType);

            // data binding
            let records = data.data.map((el, i) => {
                return  <tr>
                            <td scope="row">{ i + 1 }</td>
                            <td>{ (el.userType === 'JobSeeker') ? el.fullName : el.companyName }</td>
                            <td>{ el.name }</td>
                            <td>{ el.type }</td>
                            <td className="text-success">{ `₹ ${ el.price }` }</td>
                            <td>{ moment(el.createdAt).format('DD-MM-YYYY') }</td>
                            <td>{ moment(el.endDate).format('DD-MM-YYYY') }</td>
							<td>{ el.orderId }</td>
                            <td>{ (el.status === 1) ? 'Pending' : 'Success' }</td>
                            <td>
                                <a href="#LedgerDetailsModal" data-bs-toggle="modal" className="btn btn-sm btn-light btn-icon m-1" onClick={() => { getLedgerDetails(el) }}><img src="images/eye.svg"/></a>
                            </td>
                        </tr>
            });
            setRecords(records);
            setTotalLength(data.total * 10);
        }
        else {
            toast.error(data.message != undefined ? data.message : 'Network error!!');
            setRecords([]);
        }
    }

    const pagination = async (page) => {
        let metaData = { page, userType, searchKey, startDate, endDate };
        await getRecords(metaData);
    }

    const clearFilter = () => {
        window.location.reload(false);
    }

    const getLedgerDetails = (el) => {
        setDetails(el);
    }

    const searchRecords = (e) => {
        if(searchKey.length > 2) {
            let metaData = { page, userType, searchKey, startDate, endDate };
            getRecords(metaData);
        }
    }

    const handleSearch = (e) => {
        setSearchKey(e.target.value);
    }

    const filterByDate = () => {
        let metaData = { page, userType, searchKey, startDate, endDate };
        getRecords(metaData);
    }

    const exportData = async () => {
        let data = await getApiCall('/admin/exportOrderData', {}, true);
        if(data.status) {
            var csvString = data.data;
            var universalBOM = "\uFEFF";
            var a = window.document.createElement('a');
            a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM+csvString));
            a.setAttribute('download', 'ledger.csv');
            window.document.body.appendChild(a);
            a.click();
            window.location.reload();
        }
        else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    return (
        <>

            <div className="page-header">
                <h4>Ladger Management</h4>
            </div>
            <div className="row">
                <div className="col-12 col-sm-6 col-md-5 col-lg-4">
                    <ul className="nav nav-pills nav-justified tabs-nav bg-white">
                        <li className="nav-item">
                            <a className="nav-link active" data-bs-toggle="tab" aria-current="page" href="#JobSeeker" onClick={ ()=> { getRecords({ page: page, userType: 'JobSeeker' })}}>Job Seeker</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-bs-toggle="tab" href="#Employer" onClick={ ()=> { getRecords({ page: page, userType: 'Employer' })}}>Employer</a>
                        </li> 
                    </ul>
                </div>
                <div className="col-5 col-sm-3 col-md-3 col-lg-3 text-end">
                    <div className="btn-bg d-inline-block">
                        <a href="#" className="btn btn-sumbit btn-success" onClick={ exportData }>Export to CSV</a>
                    </div>
                </div>
            </div>
            <div className="form-box form-filter">
                <div className="row">
                    <div className="col-12 col-sm-3 col-md-3 col-lg-4">
                        <label className="mb-2">&nbsp;</label>
                        <div className="searchgroup form-group">
                            <input type="text" className="form-control form-ctrl" placeholder="Search" onChange={ (e)=> { handleSearch(e) }}/>
                            <button type="button" className="btn btn-link" onClick={ searchRecords }><img src="images/search.svg"/></button>
                        </div>
                    </div>
                    <div className="col-12 col-sm-3 col-md-3 col-lg-3">
                        <div className="form-group calendarInput">
                            <label>Start Date</label>
                            <Datepicker className="form-control form-ctrl" selected={ startDate } onChange={ (date)=> { setStartDate(date) }} />
                        </div>
                    </div>
                    <div className="col-12 col-sm-3 col-md-3 col-lg-3">
                        <div className="form-group calendarInput">
                            <label>End Date</label>
                            <Datepicker className="form-control form-ctrl" selected={ endDate } onChange={ (date)=> { setEndDate(date) }} />
                        </div>
                    </div>
                    <div className="col-7 col-sm-3 col-md-3 col-lg-2">
                        <label>&nbsp;</label>
                        <div className="btn-bg justify-content-between d-flex">
                            <a href="#" className="btn me-2 btn-sumbit btn-primary btn-block" onClick={ filterByDate }>Filter</a>
                            <a href="#" className="btn btn-sumbit btn-light btn-block" onClick={ clearFilter }>Reset</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="JobSeeker" role="tabpanel" aria-labelledby="home-tab">
                    <div className="table-card">
                        <div className="table-reponsive">
                            <table className="table table-white">
                                <thead>
                                    <tr className="table-tr-light">
                                        <th scope="col" className="text-uppercase">Sr.No </th>
                                        <th scope="col" className="text-uppercase">{ (userType === 'JobSeeker') ? 'Job Seeker' : 'Employer' }</th>
                                        <th scope="col" className="text-uppercase">Package Name</th>
                                        <th scope="col" className="text-uppercase">Type</th>
                                        <th scope="col" className="text-uppercase">Total Amount</th>
                                        <th scope="col" className="text-uppercase">Purchase Date</th>
                                        <th scope="col" className="text-uppercase">Expire Date</th>
										<th scope="col" className="text-uppercase">Transaction Id</th>
                                        <th scope="col" className="text-uppercase">Status</th>
                                        <th scope="col" className="text-uppercase">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { (records.length > 0) ? records : 'Result not found' }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <nav aria-label="Page navigation example">
                <Pagination total={totalLength} limit={10} activePage={page} prev last next first onChangePage={pagination} className="pagination justify-content-center navigation-number" />
            </nav>

            {/* Plan Details modal Start */}

            <div className="modal fade" id="LedgerDetailsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center border-0 text-center">
                            <h4 className="modal-title w-100">Plan Details</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                            <div className="modal-body">
                                <div className="form-box viewdetail">
                                    <div className="login-white-box shadow-none pt-1">
                                        <div className="row">
                                            <div className="col-6 col-sm-6 col-md-6">
                                                <div className="profile-info">
                                                    <p className="text-uppercase">Plan Name</p>
                                                    <h5 className="mb-0">{ details.name }</h5>
                                                </div>
                                            </div>
                                        <div className="col-6 col-sm-6 col-md-6">
                                            <div className="profile-info">
                                                <p className="text-uppercase">Type</p>
                                                <h5 className="mb-0">{ details.type }</h5>
                                            </div>
                                        </div>
                                        <div className="col-6 col-sm-6 col-md-6">
                                            <div className="profile-info">
                                                <p className="text-uppercase">Name</p>
                                                <h5 className="mb-0">{ (details.userType === 'JobSeeker') ? details.fullName : details.companyName }</h5>
                                            </div>
                                        </div>
                                        <div className="col-6 col-sm-6 col-md-6">
                                            <div className="profile-info">
                                                <p className="text-uppercase">Total Amount</p>
                                                <h5 className="mb-0">₹ { details.price }</h5>
                                            </div>
                                        </div>
                
                                        <div className="col-6 col-sm-6 col-md-6">
                                            <div className="profile-info">
                                                <p className="text-uppercase">Start date</p>
                                                <h5 className="mb-0">{ details.startDate }</h5>
                                            </div>
                                        </div>
                
                
                                        <div className="col-6 col-sm-6 col-md-6">
                                            <div className="profile-info">
                                                <p className="text-uppercase">End Date</p>
                                                <h5 className="mb-0">{ details.endDate }</h5>
                                            </div>
                                        </div>
										<div className="col-6 col-sm-6 col-md-6">
                                            <div className="profile-info">
                                                <p className="text-uppercase">Transaction Id</p>
                                                <h5 className="mb-0">{ details.orderId }</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="profile-info">
                                        <p className="text-uppercase">Description</p>
                                            <h5>{ (details.description) ? Parse(details.description) : '' }</h5>
                                    </div>
                                    
                                    <div className="btn-width m-auto">
                                        <button type="button" className="btn btn-block btn-normal btn-primary" data-bs-dismiss="modal" aria-label="Close">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Plan Details modal End */}

        </>
    )

}

export default DataList;