import Axios from "axios";
import swal from 'sweetalert';
import Constant from "../Components/Constant";
import loginUser from "./loginUser";
import {toast} from "react-toastify";

export default function deleteApiCall(Url, bodyData ={}, isReturn) {
    let { accessToken } = loginUser();
    return new Promise((resolve, reject) => {
        Axios.delete(Constant.apiBasePath + Url, { headers: { authorization: accessToken },
        params:bodyData
        })
            .then(data => {
                // console.log('Delete api call service success',  data.data.meta);
                if(!data.data.status){
                    // swal({ text: data.data.meta.msg, icon: "warning", dangerMode: true });
                    toast.error(data.data.message);
                }
                return resolve(data.data);
            })
            .catch(err => {
                if(err.response.status === 401){
                    sessionStorage.clear();
                    window.location.href = '/';
                    return false;
                }
                if(err.response.data.message != undefined){
                    alert(err.response.data.message);
                    return false;
                }else{
                    alert(err.response.data.message);
                    return false;
                }
            })
    })

}


