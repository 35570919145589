import { useEffect, useState } from "react";
import postApiCall from "../../Services/postApiCall";
import patchApiCall from "../../Services/patchApiCall";
import deleteApiCall from "../../Services/deleteApiCall";
import getApiCall from "../../Services/getApiCall";
import putApiCall from "../../Services/putApiCall";

import { toast, ToastContainer } from "react-toastify";
import { Pagination, Input } from 'rsuite';
import { Link } from "react-router-dom";
import moment from 'moment';
import 'rsuite/Pagination/styles/index.css';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build';


const DataList = () => {
    const [records, setRecords] = useState([])
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [totalLength, setTotalLength] = useState(0)
    const [details, setDetails] = useState({})
    const [request, setRequestParms] = useState({})
    const [blogName, setBlogName] = useState('');
	const [blogShortDescription, setBlogShortDescription] = useState('');
	const [blogDescription, setBlogDescription] = useState('');
	const [blogImage, setBlogImage] = useState('');

    useEffect(() => {
		async function getData() {
            let docs = await getApiCall("/admin/blogs", {  }, true);
            if (docs.status) {
                setPage(docs.page)
                setTotalPage(docs.total)

                // data binding

                let records = docs.data.map((el, i) => {
                    return <tr>
                                <td scope="row">{i + 1}</td>
                                <td>{ el.title }</td>
                                <td><img class="me-2 td-user" src={ el.image } /></td>
								<td className="">

                                {
                                    (el.isActive)
                                    ?
                                    <a href="#" className="btn btn-sm btn-green btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check-white.svg" /></a>
                                    :
                                    <a href="#" className="btn btn-sm btn-light btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check.svg" /></a>
                                }

                                </td>
                                <td class="text-end">
                                    <a href="#AddBlogModal" data-bs-toggle="modal" class="btn btn-light btn-sm btn-icon m-1" onClick={() => { getDetails(el._id) }}><img src="images/edit.png" /></a>
                                    <button type="button" class="btn btn-light btn-sm btn-icon  m-1" onClick={() => { isDeleted(el._id) }}><img src="images/trash.svg" /></button>
                                </td>
                            </tr>
                });

                setRecords(records);

                setTotalLength(docs.total * 10)
            } else {
                toast.error(docs.message != undefined ? docs.message : "Network error!!")
                setRecords([]);
            }
        }
        getData()

    }, []);

    const getRecords = async (page) => {

        let docs = await getApiCall("/admin/blogs", { page }, true);

        if (docs.status) {
            setPage(docs.page)
            setTotalPage(docs.total)
            setTotalLength(docs.total * 10)

            // data binding

            let records = docs.data.map((el, i) => {
                return  <tr>
                            <td scope="row">{i + 1}</td>
                            <td>{ el.title }</td>
                            <td><img class="me-2 td-user" src={ el.image } /></td>
							<td className="">

                            {
                                (el.isActive)
                                ?
                                <a href="#" className="btn btn-sm btn-green btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check-white.svg" /></a>
                                :
                                <a href="#" className="btn btn-sm btn-light btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check.svg" /></a>
                            }

                            </td>
                            <td class="text-end">
                                <a href="#AddBlogModal" data-bs-toggle="modal" class="btn btn-light btn-sm btn-icon m-1" onClick={() => { getDetails(el._id) }}><img src="images/edit.png" /></a>
                                <button type="button" class="btn btn-light btn-sm btn-icon  m-1" onClick={() => { isDeleted(el._id) }}><img src="images/trash.svg" /></button>
                            </td>
                        </tr>
            });

            setRecords(records);
        } else {
            toast.error(docs.message != undefined ? docs.message : "Network error!!");
            setRecords([]);
        }
    }

    // call change status function

    async function isActive(id) {
        let data = await patchApiCall("/admin/blogs/" + id, {}, true);
        if (data.status) {
            toast.warning(data.message)
            getRecords(page)
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }
	
    // call delete function 

    async function isDeleted(id) {
        if (window.confirm("Are you sure you want to delete this blog?")) {
            let data = await deleteApiCall("/admin/blogs/" + id, {}, true);
            if (data.status) {
                toast.warning(data.message)
                getRecords(page)
            } else {
                toast.error(data.message != undefined ? data.message : "Network error!!")
            }
        }
    }

    const setRequest = (name, e) => {
        setRequestParms({ ...request, [name]: e.target.value })
    }
    
    const createNewContent = async (e) => {
        e.preventDefault();
        let data = {};
		
		let formData = new FormData;
		formData.append('title', (request.blogName) ? request.blogName : details.title);
		formData.append('url', (request.blogName) ? request.blogName : details.url);
		formData.append('short_description', (request.blogShortDescription) ? request.blogShortDescription : details.short_description);
		formData.append('long_description', (request.blogDescription) ? request.blogDescription : details.long_description);

		console.log("formDataformData",formData)
		if(request.blogImage) {
			formData.append('image', request.blogImage, request.blogImage.name);
		}
		if(details.title) {
            
            formData.append('id', details._id);
			
            data = await putApiCall("/admin/blogs/"+details._id, formData, true);
        }
        else {
			 
            data = await postApiCall("/admin/create-blog", formData, true);
        }
        
        if (data.status) {
            toast.success(data.message)
            window.location.reload(false);
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    async function uploadImage(event) {
		setRequestParms({ ...request, ['blogImage']: event.target.files[0] })
        /*let image = event.target.files[0];
        const formData = new FormData;
        formData.append('image', image, image.name);
        let data = await postApiCall("/admin/common/upload", formData, true);
        if (data.status) {
            setRequestParms({ ...request, ['blogImage']: data.data.location  })
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }*/
    }

    async function getDetails(id) {
        let data = await getApiCall("/admin/blogs/" + id, {}, true);
        if (data.status) {
            toast.warning(data.message)
            setDetails(data.data)
            setBlogName(data.data.title);
			setBlogShortDescription(data.data.short_description);
			setBlogDescription(data.data.long_description);
			setBlogImage(data.data.image);
			
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    // get job details by id

    const pagination = async (page) => {
        await getRecords(page)
    }

    const clearDetails = () => {
        setDetails({})
		setBlogName('');
		setBlogDescription('');
		setBlogShortDescription('');
		setBlogImage('');
    }

    return (
        <>
            <ToastContainer/>
			<div class="row">
                <div class="col-7 col-sm-8 col-md-8 col-lg-8">
                    <div class="page-header">
                        <h4>Blog</h4>
                    </div>
                </div>

                <div class="col-5 col-sm-4 col-md-4 col-lg-4 form-box form-filter text-end">
                    <div class="btn-bg d-inline-block">
                        <a href="#AddBlogModal" data-bs-toggle="modal" class="btn btn-sumbit btn-success" onClick={ clearDetails }> Add New</a>
                    </div>
                </div>
            </div>
            <div class="table-card">
                <div class="table-reponsive">
                    <table class="table table-white">
                        <thead>
                            <tr class="table-tr-light">
                                <th scope="col" class="text-uppercase">Sr.No </th>
                                <th scope="col" class="text-uppercase">Title</th>
                                <th scope="col" class="text-uppercase">Image</th>
                                <th scope="col" class="text-uppercase">Status</th>
                                <th width="15%" scope="col" class="text-uppercase text-end">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            { (records.length > 0) ? records : 'No result found' }
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Add Company modal Start */}

            <div class="modal fade" id="AddBlogModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header justify-content-center border-0 text-center">
                            <h4 class="modal-title w-100">{ (details.title) ? 'Edit' : 'Add' } Blog</h4>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="form-box">
                                <form onSubmit={ createNewContent }>
                                    <div class="login-white-box shadow-none pt-1">
                                        <div class="form-group">
                                            <label>Title*</label>
                                            <input type="text" name="blogName" class="form-control form-ctrl" placeholder="Enter" defaultValue={ blogName } onChange={(evt) => setRequest("blogName", evt)}/>
                                        </div>
										<div class="form-group">
                                            <label>Short Description</label>
                                            {/*<input type="text" name="blogShortDescription" class="form-control form-ctrl" placeholder="Enter" defaultValue={ blogShortDescription } onChange={(evt) => setRequest("blogShortDescription", evt)}/>*/}
											<CKEditor 
                                               editor = { ClassicEditor }
                                               data = { blogShortDescription }
                                               onChange = { (event, editor) => { let data = editor.getData(); setRequestParms({ ...request, 'blogShortDescription': data })  }}
                                            />
                                        </div>
										<div class="form-group">
                                            <label>Description</label>
                                            {/*<input type="text" name="blogDescription" class="form-control form-ctrl" placeholder="Enter" defaultValue={ blogDescription } onChange={(evt) => setRequest("blogDescription", evt)}/>*/}
											<CKEditor 
                                               editor = { ClassicEditor }
                                               data = { blogDescription }
                                               onChange = { (event, editor) => { let data = editor.getData(); setRequestParms({ ...request, 'blogDescription': data })  }}
                                            />
                                        </div>
                                        <div class="form-group">
                                            <label>Image*</label>
                                            <div class="upload-doc-item Upload-plus">
                                                <div class="image-upload">
                                                    <input type="file" name="" id="logo" onChange={ (e) => { uploadImage(e) } }/>
                                                    <label for="logo" class="upload-field" id="file-label">
                                                        <div class="file-thumbnail">
                                                            <img id="file-image-preview" src="images/upload-blue.png" alt=""/><br/>
                                                            <h6 id="filename">Upload </h6>
                                                        </div>
                                                    </label>
                                                </div>
												{(blogImage)?<> <img src={blogImage} /></>:"" }
                                            </div>
                                        </div>
                                        <div class="btn-width m-auto">
                                            <button type="submit" class="btn btn-sumbit btn-primary btn-block">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* Add Company modal End */}

                <nav aria-label="Page navigation example">
                    <Pagination total={totalLength} limit={10} activePage={page} prev last next first onChangePage={pagination} className="pagination justify-content-center navigation-number" />
                </nav>
            </>
            );
};

export default DataList;