import React, { useState, useEffect } from "react";
import Axios from 'axios';
import Constant from "../../Components/Constant";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Input } from 'rsuite';
import { loginVailidator } from './validation/Validator'
import { userDetails } from '../../Services/authenticationService'

const Login = () => {
  const [request, setRequestParms] = useState({})
  const [validation, setValidation] = useState({})
  const [visible, setVisible] = React.useState(false);

  const formValidation = (request) => {
    let isValid = loginVailidator(request)
    if (!isValid.status) {
      toast.warning(isValid.message)
    }
  }

  useEffect(() => {
    let user = userDetails()
    if (user) {
      window.location.href = '/dashboard';
    }
  }, [])


  const setRequest = (name, value) => {
    setRequestParms({ ...request, [name]: value })
  }

  const submitHandler = async (evt) => {
    try {
      evt.preventDefault()
      formValidation(request)
      let data = await Axios.post(Constant.apiBasePath + '/admin/login', request)
      const { message, data: loginDetails, status } = data.data
      if (status) {
        sessionStorage.setItem('loginDetails', loginDetails.token);
        sessionStorage.setItem('userDetails', JSON.stringify(loginDetails));
        window.location.href = '/dashboard';
      } else {
        toast.error(message);
        return false
      }
    } catch (err) {
      const { message } = err.response.data
      toast.error(message);
    }
  }

  const handleChange = () => {
    setVisible(!visible);
  };

  return (
    <div class="login-wrap bg-primary-grandiant">
      <ToastContainer />
      <div class="container-fluid">
        <div class="form-box-login">
          <div class="row  justify-content-center">
            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
              <div class="login-white-box form-box ">
                <div class="login-img">
                  <img src="images/logo.svg" />
                </div>
                <div class="title text-center">
                  <h3>Login</h3>
                </div>
                <form onSubmit={submitHandler}>
                  <div class="form-group">
                    <label>Email Id</label>
                    <Input placeholder="Email ID" type="email" className="form-control form-ctrl" onChange={(evt) => setRequest("email", evt)}/>
                    
                  </div>
                  <div class="form-group">
                    <label>Password</label>
                    <div className="form-group mb-0 form-group-d-flex d-flex align-items-center">
                    <Input placeholder="Password" type={visible ? "text" : "password"} className="form-control form-ctrl" onChange={(evt) => setRequest("password", evt)}/>
                    <button type="button" className="btn btn-link text-dark">
                    <span
                          toggle="#password-field"
                          className="fa fa-fw field-icon toggle-password fa-eye-slash"
                          onClick={handleChange}
                        ></span>
                    </button>
                    </div>
                  </div>

                  <div class="form-group">
                    <button type="submit" class="btn btn-block btn-sumbit btn-lg btn-primary">Login</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login