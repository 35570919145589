import { useEffect, useState } from "react";
import postApiCall from "../../Services/postApiCall";
import patchApiCall from "../../Services/patchApiCall";
import deleteApiCall from "../../Services/deleteApiCall";
import getApiCall from "../../Services/getApiCall";

import { toast, ToastContainer } from "react-toastify";
import { Pagination } from 'rsuite';
import { Link } from "react-router-dom";
import moment from 'moment';
import 'rsuite/Pagination/styles/index.css';


const DataList = () => {
    const [records, setRecords] = useState([])
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [totalLength, setTotalLength] = useState(0)
    const [details, setDetails] = useState({})
    const [request, setRequestParms] = useState({})
    const [roleCategoryName, setRoleCategoryName] = useState('');

    useEffect(() => {
        async function getData() {
            let docs = await postApiCall("/admin/master/role-category", {}, true);
            if (docs.status) {
                setPage(docs.page)
                setTotalPage(docs.total)

                // data binding

                let records = docs.data.map((el, i) => {
                    return <tr>
                        <td scope="row">{i + 1}</td>
                        <td>{el.name}</td>
                        <td className="">

                            {
                                (el.status)
                                    ?
                                    <a href="#" className="btn btn-sm btn-green btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check-white.svg" /></a>
                                    :
                                    <a href="#" className="btn btn-sm btn-light btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check.svg" /></a>
                            }

                        </td>
                        <td class="text-end">
                            <a href="#AddRoleCategoryModal" data-bs-toggle="modal" class="btn btn-light btn-sm btn-icon m-1" onClick={() => { getDetails(el._id) }}><img src="images/edit.png" /></a>
                            {/* <button type="button" class="btn btn-light btn-sm btn-icon  m-1" onClick={() => { isDeleted(el._id) }}><img src="images/trash.svg" /></button> */}
                        </td>
                    </tr>
                });

                setRecords(records);

                setTotalLength(docs.total * 10)
            } else {
                toast.error(docs.message != undefined ? docs.message : "Network error!!")
                setRecords([])
            }
        }
        getData()

    }, []);

    const getRecords = async (page) => {

        let docs = await postApiCall("/admin/master/role-category", { page }, true);

        if (docs.status) {
            setPage(docs.page)
            setTotalPage(docs.total)
            setTotalLength(docs.total * 10)

            // data binding

            let records = docs.data.map((el, i) => {
                return <tr>
                    <td scope="row">{i + 1}</td>
                    <td>{el.name}</td>
                    <td className="">

                        {
                            (el.status)
                                ?
                                <a href="#" className="btn btn-sm btn-green btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check-white.svg" /></a>
                                :
                                <a href="#" className="btn btn-sm btn-light btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check.svg" /></a>
                        }

                    </td>
                    <td class="text-end">
                        <a href="#AddRoleCategoryModal" data-bs-toggle="modal" class="btn btn-light btn-sm btn-icon m-1" onClick={() => { getDetails(el._id) }}><img src="images/edit.png" /></a>
                        {/* <button type="button" class="btn btn-light btn-sm btn-icon  m-1" onClick={() => { isDeleted(el._id) }}><img src="images/trash.svg" /></button> */}
                    </td>
                </tr>
            });

            setRecords(records);
        } else {
            toast.error(docs.message != undefined ? docs.message : "Network error!!")
            setRecords([])
        }
    }

    // call change status function

    async function isActive(id) {
        let data = await patchApiCall("/admin/master/role-category/" + id, {}, true);
        if (data.status) {
            toast.warning(data.message)
            getRecords(page)
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    // call delete function 

    async function isDeleted(id) {
        if (window.confirm("Are you sure you want to delete this role category?")) {
            let data = await patchApiCall("/admin/master/role-category/" + id, {}, true);
            if (data.status) {
                toast.warning(data.message)
                getRecords(page)
            } else {
                toast.error(data.message != undefined ? data.message : "Network error!!")
            }
        }
    }

    const setRequest = (name, e) => {
        setRequestParms({ ...request, [name]: e.target.value })
    }
    
    const createNewContent = async (e) => {
        e.preventDefault();
        let data = {};
        if(details.name) {
            let metaData = {
                'id': details._id,
                'name': (request.name) ? request.name : details.name
            }

            data = await patchApiCall("/admin/master/role-category/edit", metaData, true);
        }
        else {
            data = await postApiCall("/admin/master/role-category/create", request, true);
        }
        
        if (data.status) {
            toast.warning(data.message)
            window.location.reload(false);
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    async function getDetails(id) {
        let data = await getApiCall("/admin/master/role-category/" + id, {}, true);
        if (data.status) {
            toast.warning(data.message)
            setDetails(data.data)
            setRoleCategoryName(data.data.name)
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    // get job details by id

    const pagination = async (page) => {
        await getRecords(page)
    }

    const clearDetails = () => {
        setDetails({})
		setRoleCategoryName('')
    }

    return (
        <><ToastContainer/>
            <div class="row">
                <div class="col-7 col-sm-8 col-md-8 col-lg-8">
                    <div class="page-header">
                        <h4>Role Category</h4>
                    </div>
                </div>

                <div class="col-5 col-sm-4 col-md-4 col-lg-4 form-box form-filter text-end">
                    <div class="btn-bg d-inline-block">
                        <a href="#AddRoleCategoryModal" data-bs-toggle="modal" class="btn btn-sumbit btn-success" onClick={ clearDetails }> Add New</a>
                    </div>
                </div>
            </div>
            <div class="table-card">
                <div class="table-reponsive">
                    <table class="table table-white">
                        <thead>
                            <tr class="table-tr-light">
                                <th scope="col" class="text-uppercase">Sr.No </th>
                                <th scope="col" class="text-uppercase">Category Name</th>
                                <th scope="col" class="text-uppercase">Status</th>
                                <th width="15%" scope="col" class="text-uppercase text-end">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            { (records.length > 0) ? records : 'No result found' }
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Add Company modal Start */}

            <div class="modal fade" id="AddRoleCategoryModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header justify-content-center border-0 text-center">
                            <h4 class="modal-title w-100">{ (details.name) ? 'Edit' : 'Add' } Role Category</h4>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="form-box">
                                <form onSubmit={ createNewContent }>
                                    <div class="login-white-box shadow-none pt-1">
                                        <div class="form-group">
                                            <label>Role Category Name*</label>
                                            <input type="text" name="roleCategoryName" class="form-control form-ctrl" placeholder="Enter" defaultValue={ roleCategoryName } onChange={(evt) => setRequest("name", evt)}/>
                                        </div>

                                        <div class="btn-width m-auto">
                                            <button type="submit" class="btn btn-sumbit btn-primary btn-block">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* Add Company modal End */}

            <nav aria-label="Page navigation example">
                <Pagination total={totalLength} limit={10} activePage={page} prev last next first onChangePage={pagination} className="pagination justify-content-center navigation-number" />
            </nav>
        </>
    );
};

export default DataList;