import { useEffect, useState } from "react";
import postApiCall from "../../Services/postApiCall";
import patchApiCall from "../../Services/patchApiCall";
import deleteApiCall from "../../Services/deleteApiCall";
import getApiCall from "../../Services/getApiCall";

import { toast, ToastContainer } from "react-toastify";
import { Pagination } from 'rsuite';

import { Link } from "react-router-dom";
import moment from 'moment';
import 'rsuite/Pagination/styles/index.css';

const DataList = () => {
    const [records, setRecords] = useState([])
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [totalLength, setTotalLength] = useState(0)
    const [activeStatus, setActiveStatus] = useState(true)
    const [countries, setContries] = useState([])
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])
    const [countryId, setCountryId] = useState('')
    const [ details, setDetails] = useState({});


    useEffect(() => {
        async function getData(activeStatus) {
            let docs = await postApiCall("/admin/jobseeker", { 'isActive': activeStatus }, true);
            if (docs.status) {
                setPage(docs.page)
                setTotalPage(docs.total)

                // data binding

                let records = docs.data.map((el, i) => {
                    return <tr>
                            <td scope="row">{i + 1}</td>
                            <td>
                                <div className="d-flex">
                                    <img className="me-2 td-user" src={ el.profileImage }/>
                                    <div>{ el.fullName } <br />
                                        {/* <span className="small">{ el._id }</span> */}
                                    </div>
                               </div>
                            </td>
                            {
                                (el.isActive)
                                ?
                                <td className="text-success">Active</td>
                                :
                                <td className="text-danger">In-Active</td>
                            } 
                            <td>+91 { el.mobile }</td>
                            <td>{ el.email }</td>
                            <td>{moment(el.createdAt).format('DD-MM-YYYY')}</td>
                            <td>Free</td>

                           
                            <td className="text-end">
                                {
                                    (el.isActive)
                                        ?
                                        <a href="#" className="btn btn-sm btn-green btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check-white.svg" /></a>
                                        :
                                        <a href="#" className="btn btn-sm btn-light btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check.svg" /></a>
                                }

                                    {/* <button type="button" className="btn btn-danger btn-sm btn-icon  m-1" onClick={() => { isDeleted(el._id) }}><img src="images/cross-white-x.svg" /></button> */}
                                    {/* <a href="#" className="btn btn-light btn-sm btn-icon m-1"><img src="images/file-text.svg" /></a> */}
                                    <a href="#JobSeekerDetailsModal" data-bs-toggle="modal" className="btn btn-sm btn-light btn-icon m-1" onClick={ ()=> { setDetails(el) } }><img src="images/eye.svg"/></a>
                            </td>
                        </tr>
                });

                setRecords(records);

                setTotalLength(docs.total * 10)
            } else {
                toast.error(docs.message != undefined ? docs.message : "Network error!!")
                setRecords([]);
            }
        }
        getData(activeStatus);

        // get country list 
        getCountryList();

    }, [activeStatus]);

    const getRecords = async (page, status, city, gender) => {
        let docs = await postApiCall("/admin/jobseeker", { page, 'isActive': activeStatus, city, gender }, true);
        if (docs.status) {
            setPage(docs.page)
            setTotalPage(docs.total)
            setTotalLength(docs.total * 10)

            // data binding

           // data binding

           let records = docs.data.map((el, i) => {
            return <tr>
                    <td scope="row">{i + 1}</td>
                    <td>
                        <div className="d-flex">
                            <img className="me-2 td-user" src={ el.profileImage }/>
                            <div>{ el.fullName } <br />
                            {/* <span className="small">{ el._id }</span> */}
                            </div>
                       </div>
                    </td>

                        {
                            (el.isActive)
                            ?
                            <td className="text-success">Active</td>
                            :
                            <td className="text-danger">In-Active</td>
                        } 

                    <td>+91 { el.mobile }</td>
                    <td>{ el.email }</td>
                    <td>{moment(el.createdAt).format('DD-MM-YYYY')}</td>
                    <td>Free</td>

                   
                    <td className="text-end">
                        {
                            (el.isActive)
                                ?
                                <a href="#" className="btn btn-sm btn-green btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check-white.svg" /></a>
                                :
                                <a href="#" className="btn btn-sm btn-light btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check.svg" /></a>
                        }

                            {/* <button type="button" className="btn btn-danger btn-sm btn-icon  m-1" onClick={() => { isDeleted(el._id) }}><img src="images/cross-white-x.svg" /></button> */}
                            <a href="#JobSeekerDetailsModal" data-bs-toggle="modal" className="btn btn-sm btn-light btn-icon m-1" onClick={ ()=> { setDetails(el) } }><img src="images/eye.svg"/></a>
                    </td>
                </tr>
        });

        setRecords(records);
        } else {
            toast.error(docs.message != undefined ? docs.message : "Network error!!")
            setRecords([]);
        }
    }

    // call change status function

    async function isActive(id) {
        let data = await patchApiCall("/admin/jobseeker/" + id, {}, true);
        if (data.status) {
            toast.warning(data.message)
            console.log(activeStatus)
            getRecords(page, activeStatus)
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    // call delete function 

    async function isDeleted(id) {
        if (window.confirm("Are you sure you want to delete this?")) {
            let data = await deleteApiCall("/admin/jobseeker/" + id, {}, true);
            if (data.status) {
                toast.warning(data.message)
                getRecords(page, activeStatus)
            } else {
                toast.error(data.message != undefined ? data.message : "Network error!!")
            }
        }
    }

    // get job details by id

    const pagination = async (page) => {
        await getRecords(page, activeStatus)
    }

    const filterData = async (type) => {
        await getRecords(0, type);
    }

    const resetFilter = () => {
        window.location.reload(false);
    }

    // get country list

    async function getCountryList() {
        let data = await getApiCall('/admin/common/country', {}, true);
        if (data.status) {
            let countries = data.data.map(el => {
                return <option id={ el._id } value={ el._id }>{ el.name }</option>
            });
            setContries(countries);
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    // get state list
    
    async function getStateList(e) {
        let countryId = e.target.value;
        let data = await getApiCall(`/admin/common/state?countryId=${countryId}`, {}, true);
        if (data.status) {
            setCountryId(countryId);
            let states = data.data.map(el => {
                return <option id={ el._id } value={ el._id }>{ el.name }</option>
            });
            setStates(states);
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    // get city list

    async function getCityList(e) {
        let stateId = e.target.value;
        let data = await getApiCall(`/admin/common/city?countryId=${countryId}&stateId=${stateId}`, {}, true);
        if (data.status) {
            let cities = data.data.map(el => {
                return <option id={ el._id } value={ el._id }>{ el.name }</option>
            });
            setCities(cities);
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    const exportData = async () => {
        let data = await getApiCall('/admin/exportJobSeekerData', {}, true);
        if(data.status) {
            var csvString = data.data;
            var universalBOM = "\uFEFF";
            var a = window.document.createElement('a');
            a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM+csvString));
            a.setAttribute('download', 'jobseeker.csv');
            window.document.body.appendChild(a);
            a.click();
            window.location.reload();
        }
        else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    return (
        <>
            <ToastContainer/>
            <div className="page-header">
                <h4>Job Seekers</h4>
            </div>
            <div className="row">
                <div className="col-12 col-sm-3 col-md-3 col-lg-3">
                    <ul className="nav nav-pills nav-justified tabs-nav bg-white">
                        <li className="nav-item">
                            <a className="nav-link active" data-bs-toggle="tab" aria-current="page" href="#ActiveTab" onClick={ ()=> { setActiveStatus(true) }}>Active</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-bs-toggle="tab" href="#InactiveTab" onClick={ ()=> { setActiveStatus(false) }}>Inactive</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="form-box form-filter">
                <div className="row">
                    <div className="col-12 col-sm-4 col-md-3 col-lg-2">
                        <div className="form-group">
                            <select className="form-control form-ctrl" onChange={ (e)=> { getStateList(e) }}>
                                <option>Country</option>
                                <option>Select</option>
                                { countries }
                            </select>
                        </div>
                    </div>
                    <div className="col-12 col-sm-4 col-md-3 col-lg-2">
                        <div className="form-group">
                            <select className="form-control form-ctrl" onChange={ (e)=> { getCityList(e) }}>
                                <option>State</option>
                                <option>Select</option>
                                { states }
                            </select>
                        </div>
                    </div>
                    <div className="col-12 col-sm-4 col-md-3 col-lg-2">
                        <div className="form-group">
                            <select className="form-control form-ctrl" onChange={ (e)=> { getRecords(page, activeStatus, e.target.value) }}>
                                <option>City</option>
                                <option>Select</option>
                                { cities }
                            </select>
                        </div>
                    </div>
                    {/* <div className="col-12 col-sm-4 col-md-2 col-lg-2">
                        <div className="form-group">
                            <select className="form-control form-ctrl">
                                <option>Qualification</option>
                                <option>Select</option>
                            </select>
                        </div>
                    </div> */}
                    <div className="col-12 col-sm-4 col-md-3 col-lg-2">
                        <div className="form-group">
                            <select className="form-control form-ctrl" onChange={ (e)=> { getRecords(page, activeStatus, '', e.target.value) }}>
                                <option>Gender</option>
                                <option>Select</option>
                                <option id="male" value="Male">Male</option>
                                <option id="female" value="Female">Female</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 col-md-3 col-lg-2">
                        <div className="btn-bg justify-content-between d-flex">
                            <a href="#" className="btn me-2 btn-sumbit btn-primary btn-block">Filter</a>
                            <a href="#" className="btn btn-sumbit btn-light btn-block" onClick={ resetFilter }>Reset</a>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 col-md-3 col-lg-2 text-end">
                        <div className="btn-bg d-inline-block">
                            <a href="#" className="btn btn-sumbit btn-success" onClick={ exportData }>Export to Excel</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="ActiveTab" role="tabpanel" aria-labelledby="home-tab">
                    <div className="table-card">
                        <div className="table-reponsive">
                            <table className="table table-white">
                                <thead>
                                    <tr className="table-tr-light">
                                        <th scope="col" className="text-uppercase">Sr.No </th>
                                        <th scope="col" className="text-uppercase">Name</th>
                                        <th scope="col" className="text-uppercase">Account</th>
                                        <th scope="col" className="text-uppercase">Mobile</th>
                                        <th scope="col" className="text-uppercase">Email</th>
                                        <th scope="col" className="text-uppercase">user From</th>
                                        <th scope="col" className="text-uppercase">Account Type</th>
                                        <th scope="col" className="text-uppercase text-end">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (records.length > 0)
                                        ?
                                        records
                                        :
                                        'No result found'
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <nav aria-label="Page navigation example">
                <Pagination total={totalLength} limit={10} activePage={page} prev last next first onChangePage={pagination} className="pagination justify-content-center navigation-number" />
            </nav>

            {/* Job Details modal Start */}

            <div className="modal fade" id="JobSeekerDetailsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center border-0 text-center">
                            <h4 className="modal-title w-100">Job Seeker Details</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-box viewdetail">
                                <div className="login-white-box shadow-none pt-1">

                                    <div className="row">
                                        <div className="col-6 col-sm-4 col-md-4">
                                            <div className="profile-info">
                                                <p className="text-uppercase">Mobile</p>
                                                <h5 className="mb-0">{ details.mobile }</h5>
                                            </div>
                                        </div>
                                        <div className="col-6 col-sm-4 col-md-4">
                                            <div className="profile-info">
                                                <p className="text-uppercase">Email</p>
                                                <h5 className="mb-0">{ details.email }</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 col-sm-4 col-md-4">
                                            <div className="profile-info">
                                                <p className="text-uppercase">User From</p>
                                                <h5 className="mb-0">{ moment(details.createdAt).format('DD-MM-YYYY') }</h5>
                                            </div>
                                        </div>
                                        <div className="col-6 col-sm-4 col-md-4">
                                            <div className="profile-info">
                                                <p className="text-uppercase">Account Type</p>
                                                <h5 className="mb-0">Free</h5>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="btn-width m-auto">
                                        <button type="button" className="btn btn-block btn-normal btn-primary" data-bs-dismiss="modal" aria-label="Close">Close</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* Job Details modal End */}

        </>
        );
};

export default DataList;