import { useEffect, useState } from "react";
import postApiCall from "../../Services/postApiCall";
import patchApiCall from "../../Services/patchApiCall";
import deleteApiCall from "../../Services/deleteApiCall";
import getApiCall from "../../Services/getApiCall";

import { toast, ToastContainer } from "react-toastify";
import { Pagination } from 'rsuite';
import { Link } from "react-router-dom";
import moment from 'moment';
import 'rsuite/Pagination/styles/index.css';


const DataList = () => {
    const [records, setRecords] = useState([])
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [totalLength, setTotalLength] = useState(0)
    const [details, setDetails] = useState({})
    const [request, setRequestParms] = useState({})
    const [industryName, setIndustryName] = useState('');
	const [industryImage, setIndustryImage] = useState('');

    useEffect(() => {
        async function getData() {
            let docs = await postApiCall("/admin/master/industry", {}, true);
            if (docs.status) {
                setPage(docs.page)
                setTotalPage(docs.total)

                // data binding

                let records = docs.data.map((el, i) => {
                    return <tr>
                        <td scope="row">{i + 1}</td>
                        <td>{el.industry}</td>
						<td><img class="me-2 td-user" src={ el.image } /></td>
                        <td className="">

                            {
                                (el.status)
                                    ?
                                    <a href="#" className="btn btn-sm btn-green btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check-white.svg" /></a>
                                    :
                                    <a href="#" className="btn btn-sm btn-light btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check.svg" /></a>
                            }

                        </td>
                        <td className="text-end">
                            <a href="#AddIndustryModal" data-bs-toggle="modal" className="btn btn-light btn-sm btn-icon m-1" onClick={() => { getDetails(el._id) }}><img src="images/edit.png" /></a>
                            {/* <button type="button" className="btn btn-light btn-sm btn-icon  m-1" onClick={() => { isDeleted(el._id) }}><img src="images/trash.svg" /></button> */}
                        </td>
                    </tr>
                });

                setRecords(records);

                setTotalLength(docs.total * 10)
            } else {
                toast.error(docs.message != undefined ? docs.message : "Network error!!")
                setRecords([]);
            }
        }
        getData()

    }, []);

    const getRecords = async (page) => {

        let docs = await postApiCall("/admin/master/industry", { page }, true);

        if (docs.status) {
            setPage(docs.page)
            setTotalPage(docs.total)
            setTotalLength(docs.total * 10)

            // data binding

            let records = docs.data.map((el, i) => {
                return <tr>
                    <td scope="row">{i + 1}</td>
                    <td>{el.industry}</td>
					<td><img class="me-2 td-user" src={ el.image } /></td>
                    <td className="">

                        {
                            (el.status)
                                ?
                                <a href="#" className="btn btn-sm btn-green btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check-white.svg" /></a>
                                :
                                <a href="#" className="btn btn-sm btn-light btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check.svg" /></a>
                        }

                    </td>
                    <td className="text-end">
                        <a href="#AddIndustryModal" data-bs-toggle="modal" className="btn btn-light btn-sm btn-icon m-1" onClick={() => { getDetails(el._id) }}><img src="images/edit.png" /></a>
                        {/* <button type="button" className="btn btn-light btn-sm btn-icon  m-1" onClick={() => { isDeleted(el._id) }}><img src="images/trash.svg" /></button> */}
                    </td>
                </tr>
            });

            setRecords(records);
        } else {
            toast.error(docs.message != undefined ? docs.message : "Network error!!")
            setRecords([]);
        }
    }

    // call change status function

    async function isActive(id) {
        let data = await patchApiCall("/admin/master/industry/" + id, {}, true);
        if (data.status) {
            toast.warning(data.message)
            getRecords(page)
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    // call delete function 

    async function isDeleted(id) {
        if (window.confirm("Are you sure you want to delete this industry?")) {
            let data = await patchApiCall("/admin/master/industry/" + id, {}, true);
            if (data.status) {
                toast.warning(data.message)
                getRecords(page)
            } else {
                toast.error(data.message != undefined ? data.message : "Network error!!")
            }
        }
    }

    const setRequest = (name, e) => {
        setRequestParms({ ...request, [name]: e.target.value })
    }


	async function uploadImage(event) {
        let image = event.target.files[0];
        const formData = new FormData;
        formData.append('image', image, image.name);
        let data = await postApiCall("/admin/common/upload", formData, true);
        if (data.status) {
            setRequestParms({ ...request, ['industryImage']: data.data.location  })
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }
	
    const createNewContent = async (e) => {
        e.preventDefault();
        let data = {};
        if(details.industry) {
            let metaData = {
                'id': details._id,
                'industry': (request.industry) ? request.industry : details.industry,
				'image': (request.industryImage) ? request.industryImage : details.image
            }

            data = await patchApiCall("/admin/master/industry/edit", metaData, true);
        }
        else {
			let metaData = {
                'industry': (request.industry) ? request.industry : '',
				'image': (request.industryImage) ? request.industryImage : ''
            }
			
            data = await postApiCall("/admin/master/industry/create", metaData, true);
        }
        
        if (data.status) {
            toast.warning(data.message)
            window.location.reload(false);
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    async function getDetails(id) {
        let data = await getApiCall("/admin/master/industry/" + id, {}, true);
        if (data.status) {
            toast.warning(data.message)
            setDetails(data.data)
            setIndustryName(data.data.industry)
			setIndustryImage(data.data.image)
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    // get job details by id

    const pagination = async (page) => {
        await getRecords(page)
    }

    const clearDetails = () => {
        setDetails({})
		setIndustryName('')
		setIndustryImage('')
    }

    return (
        <><ToastContainer/>
            <div className="row">
                <div className="col-7 col-sm-8 col-md-8 col-lg-8">
                    <div className="page-header">
                        <h4>Industry</h4>
                    </div>
                </div>

                <div className="col-5 col-sm-4 col-md-4 col-lg-4 form-box form-filter text-end">
                    <div className="btn-bg d-inline-block">
                        <a href="#AddIndustryModal" data-bs-toggle="modal" className="btn btn-sumbit btn-success" onClick={ clearDetails }> Add New</a>
                    </div>
                </div>
            </div>
            <div className="table-card">
                <div className="table-reponsive">
                    <table className="table table-white">
                        <thead>
                            <tr className="table-tr-light">
                                <th scope="col" className="text-uppercase">Sr.No </th>
                                <th scope="col" className="text-uppercase">Industry Name</th>
								<th scope="col" class="text-uppercase">Industry Image</th>
                                <th scope="col" className="text-uppercase">Status</th>
                                <th width="15%" scope="col" className="text-uppercase text-end">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            { (records.length > 0) ? records : 'No result found' }
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Add Company modal Start */}

            <div className="modal fade" id="AddIndustryModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center border-0 text-center">
                            <h4 className="modal-title w-100">{ (details.industry) ? 'Edit' : 'Add' } Industry</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-box">
                                <form onSubmit={ createNewContent }>
                                    <div className="login-white-box shadow-none pt-1">
                                        <div className="form-group">
                                            <label>Industry Name*</label>
                                            <input type="text" name="industry" className="form-control form-ctrl" placeholder="Enter" defaultValue={ industryName } onChange={(evt) => setRequest("industry", evt)}/>
                                        </div>
										<div class="form-group">
                                            <label>Industry Image*</label>
                                            <div class="upload-doc-item Upload-plus">
                                                <div class="image-upload">
                                                    <input type="file" name="" id="logo" onChange={ (e) => { uploadImage(e) } }/>
                                                    <label for="logo" class="upload-field" id="file-label">
                                                        <div class="file-thumbnail">
                                                            <img id="file-image-preview" src="images/upload-blue.png" alt=""/><br/>
                                                            <h6 id="filename">Upload </h6>
                                                        </div>
                                                    </label>
                                                </div>
												{(industryImage)?<> <img src={industryImage} /></>:"" }
                                            </div>
                                        </div>

                                        <div className="btn-width m-auto">
                                            <button type="submit" className="btn btn-sumbit btn-primary btn-block">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* Add Company modal End */}

            <nav aria-label="Page navigation example">
                <Pagination total={totalLength} limit={10} activePage={page} prev last next first onChangePage={pagination} className="pagination justify-content-center navigation-number" />
            </nav>
        </>
    );
};

export default DataList;