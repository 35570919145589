import { useEffect, useState } from "react";
import postApiCall from "../../Services/postApiCall";
import patchApiCall from "../../Services/patchApiCall";
import deleteApiCall from "../../Services/deleteApiCall";
import getApiCall from "../../Services/getApiCall";

import { toast, ToastContainer } from "react-toastify";
import { Pagination } from 'rsuite';
import { Link } from "react-router-dom";
import moment from 'moment';
import 'rsuite/Pagination/styles/index.css';


const DataList = () => {
    const [records, setRecords] = useState([])
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [totalLength, setTotalLength] = useState(0)
    const [details, setDetails] = useState({})
    const [request, setRequestParms] = useState({})
    const [name, setName] = useState('');
    const [industries, setIndustries] = useState([])
	const [departments, setDepartments] = useState([])


    useEffect(() => {
        async function getData() {
            let docs = await getApiCall("/admin/master/subcategory", {}, true);
            if (docs.status) {
                setPage(docs.page)
                setTotalPage(docs.total)

                // data binding

                let records = docs.data.map((el, i) => {
                    return <tr>
                        <td scope="row">{i + 1}</td>
                        <td>{el.name}</td>
                        <td>{el.industry.industry}</td>
						<td>{el.department.department}</td>
                        <td>

                            {
                                (el.isActive)
                                    ?
                                    <a href="#" className="btn btn-sm btn-green btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check-white.svg" /></a>
                                    :
                                    <a href="#" className="btn btn-sm btn-light btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check.svg" /></a>
                            }

                        </td>
                        <td className="text-end">
                            <a href="#AddDepartmentModal" data-bs-toggle="modal" className="btn btn-light btn-sm btn-icon m-1" onClick={() => { getDetails(el._id) }}><img src="images/edit.png" /></a>
                            {/* <button type="button" className="btn btn-light btn-sm btn-icon  m-1" onClick={() => { isDeleted(el._id) }}><img src="images/trash.svg" /></button> */}
                        </td>
                    </tr>
                });

                setRecords(records);

                setTotalLength(docs.total * 10)
            } else {
                toast.error(docs.message != undefined ? docs.message : "Network error!!")
                setRecords([]);
            }
        }
        getData();

        // get industry list

        getIndustryList();
		//getDepartmentList();

    }, []);

    const getRecords = async (page,industryId='') => {

        let docs = await getApiCall("/admin/master/subcategory", { page, industryId }, true);

        if (docs.status) {
            setPage(docs.page)
            setTotalPage(docs.total)
            setTotalLength(docs.total * 10)

            // data binding

            let records = docs.data.map((el, i) => {
                return <tr>
                    <td scope="row">{i + 1}</td>
                    <td>{el.name}</td>
                    <td>{el.industry.industry}</td>
					<td>{el.department.department}</td>
                    <td>

                        {
                            (el.isActive)
                                ?
                                <a href="#" className="btn btn-sm btn-green btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check-white.svg" /></a>
                                :
                                <a href="#" className="btn btn-sm btn-light btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check.svg" /></a>
                        }

                    </td>
                    <td className="text-end">
                        <a href="#AddDepartmentModal" data-bs-toggle="modal" className="btn btn-light btn-sm btn-icon m-1" onClick={() => { getDetails(el._id) }}><img src="images/edit.png" /></a>
                        {/* <button type="button" className="btn btn-light btn-sm btn-icon  m-1" onClick={() => { isDeleted(el._id) }}><img src="images/trash.svg" /></button> */}
                    </td>
                </tr>
            });

            setRecords(records);
        } else {
            toast.error(docs.message != undefined ? docs.message : "Network error!!");
            setRecords([]);
        }
    }

    // call change status function

    async function isActive(id) {
        let data = await patchApiCall("/admin/master/subcategory/" + id, {}, true);
        if (data.status) {
            toast.warning(data.message)
            getRecords(page)
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    // call delete function 

    async function isDeleted(id) {
        if (window.confirm("Are you sure you want to delete this sub department?")) {
            let data = await patchApiCall("/admin/master/subcategory/" + id, {}, true);
            if (data.status) {
                toast.warning(data.message)
                getRecords(page)
            } else {
                toast.error(data.message != undefined ? data.message : "Network error!!")
            }
        }
    }

    const setRequest = (name, e) => {
        setRequestParms({ ...request, [name]: e.target.value })
		if(name=='industries')
		{
			getDepartmentRecords(e.target.value);
		}
			
    }
	
	
    const getDepartmentRecords = async (industryId) => {

        let docs = await postApiCall("/admin/master/department", {industryId }, true);

        if (docs.status) {
            setDepartments(docs.data);
        } else {
            toast.error(docs.message != undefined ? docs.message : "Network error!!");
            setDepartments([]);
        }
    }
	
	/*
	async function getDepartmentList() {
        let data = await postApiCall('/admin/master/department', {}, true);
        if (data.status) {
            setDepartments(data.data);
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }
	*/


    const createNewContent = async (e) => {
        e.preventDefault();
        let data = {};
        if(details.name) {
            let metaData = {
                'id': details._id,
                'name': (request.name) ? request.name : details.name,
                'industryId': (request.industries) ? request.industries : details.industryId,
				'parentId': (request.department) ? request.department : details.parentId
            }

            data = await patchApiCall("/admin/master/subcategory/edit", metaData, true);
        }
        else {
			
			let metaData = {
                'name': (request.name) ? request.name : '',
                'industryId': (request.industries) ? request.industries : '',
				'parentId': (request.department) ? request.department : ''
            }
			
            data = await postApiCall("/admin/master/subcategory/create", metaData, true);
        }
        
        if (data.status) {
            toast.warning(data.message)
            window.location.reload(false);
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    async function getDetails(id) {
        let data = await getApiCall("/admin/master/subcategory/" + id, {}, true);
        if (data.status) {
            toast.warning(data.message)
            setDetails(data.data)
            setName(data.data.name);
			getDepartmentRecords(data.data.industryId);
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    // get job details by id

    const pagination = async (page) => {
        await getRecords(page)
    }

    // get industry list

    async function getIndustryList() {
        let data = await getApiCall('/admin/common/industry', {}, true);
        if (data.status) {
            setIndustries(data.data);
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }
	
	const clearFormData = () => {
        setName('');
        setDetails({});
    }
	
	async function getSubDepartmentList(e) {
        let industryId = e.target.value;
		getRecords(page,industryId)
    }

    
    return (
        <><ToastContainer/>
            <div className="row">
                <div className="col-7 col-sm-8 col-md-8 col-lg-8">
                    <div className="page-header">
                        <h4>Job Title</h4>
                    </div>
                </div>

                <div className="col-5 col-sm-4 col-md-4 col-lg-4 form-box form-filter text-end">
                    <div className="btn-bg d-inline-block">
                        <a href="#AddDepartmentModal" data-bs-toggle="modal" className="btn btn-sumbit btn-success" onClick={ clearFormData }> Add New</a>
                    </div>
                </div>
            </div>
			<div className="form-box form-filter">
                <div className="row">
                    <div className="col-12 col-sm-4 col-md-3 col-lg-2">
                        <div className="form-group">
                            <select className="form-control form-ctrl" onChange={ (e)=> { getSubDepartmentList(e) }} >
                                <option value="">Select Industry</option>
                                {  
									   (industries.length > 0) 
									?
										industries.map(el => {
											
											return <option id={ el._id } value={ el._id }>{ el.industry }</option>
											
										}) 
									:
										''
								}
                            </select>
                        </div>
                    </div>
				</div>
			</div>
            <div className="table-card">
                <div className="table-reponsive">
                    <table className="table table-white">
                        <thead>
                            <tr className="table-tr-light">
                                <th scope="col" className="text-uppercase">Sr.No </th>
                                <th scope="col" className="text-uppercase">Job Title Name</th>
                                <th scope="col" className="text-uppercase">Industry Name</th>
								<th scope="col" className="text-uppercase">Department Name</th>
                                <th scope="col" className="text-uppercase">Status</th>
                                <th width="15%" scope="col" className="text-uppercase text-end">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            { (records.length > 0) ? records : 'No result found' }
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Department modal Start */}

            <div className="modal fade" id="AddDepartmentModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center border-0 text-center">
                            <h4 className="modal-title w-100">{ (details.name) ? 'Edit' : 'Add' } Job Title</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-box">
                                <form onSubmit={ createNewContent }>
                                    <div className="login-white-box shadow-none pt-1">
                                        <div className="form-group">
                                            <label>Industry Name*</label>
                                            <select className="form-control form-ctrl" onChange={(evt) => setRequest("industries", evt)}>
                                                <option>Select One</option>

                                                {  
                                                       (industries.length > 0) 
                                                    ?
                                                        industries.map(el => {
                                                            if(details.industryId) {
                                                                if(el._id === details.industryId) {
                                                                    return <option id={ el._id } value={ el._id } selected={ true }>{ el.industry }</option>
                                                                }
                                                                else {
                                                                    return <option id={ el._id } value={ el._id }>{ el.industry }</option>
                                                                }
                                                            }
                                                            else {
                                                                return <option id={ el._id } value={ el._id }>{ el.industry }</option>
                                                            }
                                                        }) 
                                                    :
                                                        ''
                                                }

                                            </select>
                                        </div>
										<div className="form-group">
                                            <label>Department Name*</label>
                                            <select className="form-control form-ctrl" onChange={(evt) => setRequest("department", evt)}>
                                                <option>Select One</option>

                                                {  
                                                       (departments.length > 0) 
                                                    ?
                                                        departments.map(el => {
                                                            if(details.parentId) {
                                                                if(el._id === details.parentId) {
                                                                    return <option id={ el._id } value={ el._id } selected={ true }>{ el.department }</option>
                                                                }
                                                                else {
                                                                    return <option id={ el._id } value={ el._id }>{ el.department }</option>
                                                                }
                                                            }
                                                            else {
                                                                return <option id={ el._id } value={ el._id }>{ el.department }</option>
                                                            }
                                                        }) 
                                                    :
                                                        ''
                                                }

                                            </select>
                                        </div>
										<div className="form-group">
                                            <label>Job Title Name*</label>
                                            <input type="text" name="name" className="form-control form-ctrl" placeholder="Enter" defaultValue={ name } onChange={(evt) => setRequest("name", evt)}/>
                                        </div>
                                        

                                        <div className="btn-width m-auto">
                                            <button type="submit" className="btn btn-sumbit btn-primary btn-block">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* Department modal End */}

            <nav aria-label="Page navigation example">
                <Pagination total={totalLength} limit={10} activePage={page} prev last next first onChangePage={pagination} className="pagination justify-content-center navigation-number" />
            </nav>
        </>
    );
};

export default DataList;