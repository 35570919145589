import { useEffect, useState } from "react";
import getApiCall from "../../Services/getApiCall";

import { toast, ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';

import 'rsuite/Pagination/styles/index.css';


const Profile = (props) => {

    const [details, setDetails] = useState({});

    useEffect(() => {
        async function getData() {
            let data = await getApiCall('/admin/profile', {}, true);
            if (data.status) {
                setDetails(data.data);
            }
            else {
                toast.error(data.message != undefined ? data.message : 'Network error!!');
                setDetails([]);
            }
        }
        getData()

    }, []);


    return (
        <>
            <div className="dashboardMain">
                <div className="page-header">
                    <div className="row">
                        <div className="col-7 col-sm-8 col-md-9 col-lg-9 col-xl-10">
                            <h4>My Profile</h4>
                        </div>
                        <div className="col-5 col-sm-4 col-md-3 col-lg-3 col-xl-2 text-end form-box form-filter">
                            <Link to="/edit-profile" className="btn btn-block btn-normal btn-radius btn-primary">Edit Profile</Link>
                        </div>
                    </div>
                </div>
                <div className="account_dashboard profile_dashboard">
                    <div className="login-white-box">
                        <div className="row align-items-center">
                            <div className="col-5 col-sm-4 col-md-3 col-lg-2">
                                <div className="user_info">
                                    <div className="profile-pic">
                                        <img src={ details.image } alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-7 col-sm-8 col-md-9 col-lg-10">
                                <div className="form-group">
                                    <label>User Name</label>
                                    <h5>{ details.userName }</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default Profile;