import { useEffect, useState } from "react";
import postApiCall from "../../Services/postApiCall";
import putApiCall from "../../Services/putApiCall";
import patchApiCall from "../../Services/patchApiCall";
import deleteApiCall from "../../Services/deleteApiCall";

import { toast, ToastContainer } from 'react-toastify';
import { Pagination } from 'rsuite';
import { Link } from 'react-router-dom';
import moment from 'moment';
import $ from 'jquery';
import 'rsuite/Pagination/styles/index.css';


const DataList = (props) => {

    const [records, setRecords] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [totalLength, setTotalLength] = useState(0);
    const [details, setDetails] = useState({});
    const [request, setRequestParms] = useState({});

    useEffect(() => {
        $(".common-btn-class").attr('disabled', 'disabled');

        async function getData() {
            let data = await postApiCall('/admin/company-rating', { page }, true);
            if (data.status) {
                setPage(data.page);
                setTotalPage(data.total);

                // data binding
                let records = data.data.map((el, i) => {
                    return  <tr>
                                <td scope="row">{ i + 1 }</td>
                                <td><input type="checkbox" className="checkOne" id={ el._id } name="reviewCheckbox" value={ el._id }/></td>
                                <td>{ el.fullName }</td>
                                <td>{ el.companyName }</td>
                                <td><a className="btn btn-warning btn-sm"><i className="fa fa-star"> { el.rating }</i></a></td>
                                <td>{ el.review }</td>
                                <td>{ moment(el.createdAt).format('DD-MM-YYYY') }</td>
                                <td>{ el.status }</td>
                                {/* <td> */}
                                    {/* <a href="#editReviewModal" data-bs-toggle="modal" className="btn btn-light btn-sm btn-icon m-1"><img src="images/edit.png" onClick={ setDetails(el) }/></a> */}
                                {/* </td> */}
                            </tr>
                });
                setRecords(records);
                setTotalLength(data.total * 10);
            }
            else {
                toast.error(data.message != undefined ? data.message : 'Network error!!');
                setRecords([]);
            }
        }
        getData()

    }, []);

    const getRecords = async (page) => {
        let data = await postApiCall('/admin/company-rating', { page }, true);
        if (data.status) {
            setPage(data.page);
            setTotalPage(data.total);

            // data binding
            let records = data.data.map((el, i) => {
                return  <tr>
                            <td scope="row">{ i + 1 }</td>
                            <td><input type="checkbox" className="checkOne" id={ el._id } name="reviewCheckbox" value={ el._id }/></td>
                            <td>{ el.fullName }</td>
                            <td>{ el.companyName }</td>
                            <td><a className="btn btn-warning btn-sm"><i className="fa fa-star">{ el.rating }</i></a></td>
                            <td>{ el.review }</td>
                            <td>{ moment(el.createdAt).format('DD-MM-YYYY') }</td>
                            <td>{ el.status }</td>
                            {/* <td> */}
                                {/* <a href="#editReviewModal" onClick={ setDetails(el) } data-bs-toggle="modal" className="btn btn-light btn-sm btn-icon m-1"><img src="images/edit.png"/></a> */}
                            {/* </td> */}
                        </tr>
            });
            setRecords(records);
            setTotalLength(data.total * 10);
        }
        else {
            toast.error(data.message != undefined ? data.message : 'Network error!!');
            setRecords([]);
        }
    }

    const pagination = async (page) => {
        await getRecords(page)
    }

    async function isActive() {
        let ids = [];
        $('input[name="reviewCheckbox"]:checked').each(function() {
            ids.push(this.value);
        });

        let data = await patchApiCall("/admin/company-rating", { 'ids': ids }, true);
        if (data.status) {
            toast.warning(data.message)
            getRecords(page)
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    // call delete function 

    async function isDeleted() {
        let ids = [];
        $('input[name="reviewCheckbox"]:checked').each(function() {
            ids.push(this.value);
        });

        if (window.confirm("Are you sure you want to delete selected reviews?")) {
            let data = await patchApiCall("/admin/company-rating/delete", { 'ids': ids }, true);
            if (data.status) {
                toast.warning(data.message)
                getRecords(page)
            } else {
                toast.error(data.message != undefined ? data.message : "Network error!!")
            }
        }
    }

    const setRequest = (name, e) => {
        setRequestParms({ ...request, [name]: e.target.value })
    }

    const editReview = async (e) => {
        e.preventDefault();
        let metaData = {
            'review': (request.review) ? request.review : details.review
        }

        let data = await putApiCall(`/admin/company-rating/${details._id}`, metaData, true);
        
        if (data.status) {
            toast.warning(data.message)
            window.location.reload(false);
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    var clicked = false;

    $(".checkAll").on("click", function() {
        $(".checkOne").prop("checked", !clicked);
        clicked = !clicked;
        // this.innerHTML = clicked ? 'Deselect' : 'Select';

        // make active action button on click
        if($('.checkAll').is(':checked')) {
            $(".common-btn-class").removeAttr('disabled');
        }
        else {
            $(".common-btn-class").attr('disabled', 'disabled');
        }
        
    })

     // make active action button on click
     
    $(".checkOne").on("click", function() {
        if($('.checkOne').is(':checked')) {
            $(".common-btn-class").removeAttr('disabled');
        }
        else {
            $(".common-btn-class").attr('disabled', 'disabled');
        }
    })

    return (
        <>

            <div>
                <div className="dashboardMain">
                    <div className="page-header">
                        <h4>Rating & Review Management</h4>
                    </div>

                    <div className="table-card">
                        <div className="table-reponsive">
                            <button type="button" className="btn btn-danger me-2 common-btn-class" onClick={ isDeleted }>Delete Selected</button>
                            <button type="button" className="btn btn-success common-btn-class" onClick={ isActive }>Approve Selected</button>
                            <table className="table table-white">
                                <thead>
                                    <tr className="table-tr-light">
                                        <th scope="col" className="text-uppercase">Sr.No </th>
                                        <th><input type="checkbox" id="all" name="all" value="all" className="checkAll"/></th>
                                        <th scope="col" className="text-uppercase">Name</th>
                                        <th scope="col" className="text-uppercase">Company Name</th>
                                        <th scope="col" className="text-uppercase">Rating</th>
                                        <th width="30%" scope="col" className="text-uppercase">Review </th>
                                        <th scope="col" className="text-uppercase">Date</th>
                                        <th scope="col" className="text-uppercase">Status</th>
                                        {/* <th scope="col" className="text-uppercase">Action</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    { (records.length > 0) ? records : 'No result found' }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/* Department modal Start */}

            <div className="modal fade" id="editReviewModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center border-0 text-center">
                            <h4 className="modal-title w-100">Edit</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-box">
                                <form onSubmit={ editReview }>
                                    <div className="login-white-box shadow-none pt-1">
                                        <div className="form-group">
                                            <label>Review</label>
                                            <input type="text" name="review" className="form-control form-ctrl" placeholder="Enter" defaultValue={ details.review } onChange={(evt) => setRequest("review", evt)}/>
                                        </div>

                                        <div className="btn-width m-auto">
                                            <button type="submit" className="btn btn-sumbit btn-primary btn-block">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <nav aria-label="Page navigation example">
                <Pagination total={totalLength} limit={10} activePage={page} prev last next first onChangePage={pagination} className="pagination justify-content-center navigation-number" />
            </nav>

        </>
    )

}

export default DataList;