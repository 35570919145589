import Axios from "axios";
import swal from 'sweetalert';
import Constant from "../Components/Constant";
import loginUser from "./loginUser";
import {toast} from "react-toastify";

export default function patchApiCall(Url, bodyData, isReturn) {
    let { accessToken } = loginUser();
    return new Promise((resolve, reject) => {
        Axios.patch(Constant.apiBasePath + Url,bodyData,{ headers: { authorization: accessToken }})
            .then(data => {
                // console.log('Put api service success',  data.data.meta);
                if(!data.data.status){
                    swal({ text: data.data.message, icon: "warning", dangerMode: true });
                }
                return resolve(data.data);
            })
            .catch(err => {
                if(err.response.status === 401){
                    sessionStorage.clear();
                    window.location.href = '/';
                    return false;
                }
                if(err.response.data.message != undefined){
                    alert(err.response.data.message);
                    return false;
                }else{
                    alert(err.response.data.message);
                    return false;
                }
            })
    })

}


