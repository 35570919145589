import { useEffect, useState } from "react";
import getApiCall from "./Services/getApiCall";
import { toast, ToastContainer } from "react-toastify";
import { Pagination } from 'rsuite';
import { Link } from "react-router-dom";
import 'rsuite/Pagination/styles/index.css';
import { propTypes } from "react-bootstrap/esm/Image";
import { Logout, userDetails } from "./Services/authenticationService";
import $ from 'jquery';

const Layout = (props) => {

    const [details, setDetails] = useState({});

    useEffect(() => {
        async function getData() {
            let data = await getApiCall('/admin/profile', {}, true);
            if (data.status) {
                setDetails(data.data);
            }
            else {
                toast.error(data.message != undefined ? data.message : 'Network error!!');
                setDetails([]);
            }
        }
        getData()

    }, []);

    $(".nav-menu").on("click", "li", function() {
        $(".nav-menu li.active").removeClass("active");
        $(this).addClass("active");
    })

    return (
        <>
            <div className="sidebar-header">
                <div className="sidebar-brand">
                    <Link to="/dashboard"><img src="/images/logo.svg" /></Link>
                </div>
                <div className="siderbar-menu">
                    <ul className="list-unstyled nav-menu">
                        <li className="active"><Link to="/dashboard"><img src="/images/dashboard-icon.svg" /><img className="img-hover" src="/images/dashboard-icon-white.svg" /><span>Dashboard</span></Link></li>
                        <li id="job_seeker"><Link to="/job-seeker-management"><img src="/images/dashboard-icon0.svg" /><img className="img-hover" src="/images/dashboard-icon-white0.svg" /><span>Job Seeker MGT</span></Link></li>
                        <li className=""><Link to="job-management"><img src="/images/dashboard-icon1.svg" /><img className="img-hover" src="/images/dashboard-icon-white1.svg" /><span>Job MGT</span></Link></li>

                        <li className=""><Link to="/employer-management"><img src="/images/dashboard-icon2.svg" /><img className="img-hover" src="/images/dashboard-icon-white2.svg" /><span>Employer MGT</span></Link></li>
                        <li><Link to="/ledger-management"><img src="/images/dashboard-icon3.svg" /><img className="img-hover" src="/images/dashboard-icon-white3.svg" /><span>Ledger MGT</span></Link></li>
                        <li><Link to="/complaint-management"><img src="/images/dashboard-icon4.svg" /><img className="img-hover" src="/images/dashboard-icon-white4.svg" /><span>Complaint MGT</span></Link></li>
                        <li><Link to="/banner-management"><img src="/images/dashboard-icon5.svg" /><img className="img-hover" src="/images/dashboard-icon-white5.svg" /><span>Banner MGT</span></Link></li>
                        <li><Link to="/membership-plan-management"><img src="/images/dashboard-icon6.svg" /><img className="img-hover" src="/images/dashboard-icon-white6.svg" /><span>Membership Plan MGT</span></Link></li>
                        <li><Link to="/rating-review-management"><img src="/images/dashboard-icon7.svg" /><img className="img-hover" src="/images/dashboard-icon-white7.svg" /><span>Rating and Review MGT</span></Link></li>
                        <li><Link to="/notification-management"><img src="/images/dashboard-icon7.svg" /><img className="img-hover" src="/images/dashboard-icon-white7.svg" /><span>Notification Management</span></Link></li>

                        <li className="dropdown"><a className="dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" href="#"><img src="/images/settings.png"/><img className="img-hover" src="/images/settings-white.png"/><span>Master</span></a>
                            <ul className="dropdown-menu side-sub-menu" aria-labelledby="dropdownMenuButton1">
                                <li className=""><Link to="/country-management" className="dropdown-item">Country</Link></li>
                                <li className=""><Link to="/state-management" className="dropdown-item">State</Link></li>
                                <li className=""><Link to="/city-management" className="dropdown-item">City</Link></li>
                                <li className=""><Link to="/company-management" className="dropdown-item">Company</Link></li>
                                <li className=""><Link to="/industry-management" className="dropdown-item">Industry</Link></li>
                                <li className=""><Link to="/department-management" className="dropdown-item">Department</Link></li>
								<li className=""><Link to="/subdepartment-management" className="dropdown-item">Job Title</Link></li>
                                <li className=""><Link to="/role-category-management" className="dropdown-item">Role Category</Link></li>
                                <li className=""><Link to="/roles-management" className="dropdown-item">Roles</Link></li>
								<li className=""><Link to="/language-management" className="dropdown-item">Language</Link></li>
								
                            </ul>
                        </li>
						<li className=""><Link to="/blog-management"><img src="/images/cms.png"/><img class="img-hover" src="/images/cms-white.png"/><span>Blog</span></Link></li>
                        <li className=""><Link to="/cms"><img src="/images/cms.png"/><img class="img-hover" src="/images/cms-white.png"/><span>CMS MGT</span></Link></li>
						<li className=""><Link to="/newsletter"><img src="/images/cms.png"/><img class="img-hover" src="/images/cms-white.png"/><span>Newsletter</span></Link></li>
						<li className=""><Link to="/site-info"><img src="/images/cms.png"/><img class="img-hover" src="/images/cms-white.png"/><span>Site Information</span></Link></li>
                        <li><a href="#" onClick={Logout}><img src="/images/Logout.svg" /><img className="img-hover" src="/images/Logout-white.svg" /><span>Logout</span></a></li>
                    </ul>
                    

                </div>
            </div>
            <div className="content">
                <header>
                    <nav id="navbar-example2" className="navbar navbar-expand-lg sticky-top navbar-light bg-light">
                        <div className="container-fluid">
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <a className="navbar-brand" href="dashboard.php">Welcome Back!<span>Admin</span></a>
                            <ul className="navbar-nav ms-auto flex-nowrap d-flex mobile-nav align-items-center">
                                {/* <li className="nav-item">
                                    <div className="mobilesrch searchbox-icon"><img src="images/search.png" /></div>
                                    <form method="GET" className="searchbox navbar-form" action="#">
                                        <div className="form-group mb-0">
                                            <input className="searchbox-input form-control" type="text" name="search" id="searchterm" placeholder="Search" autocomplete="off" required="" value="" />
                                        </div>
                                        <button className="submits searchbox-submit btn btn-default" id="submit" type="submit"><img src="/images/search.svg" /></button></form>
                                </li> */}
                                {/* <li className="nav-item dropdown notification-nav-item">
                                    <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown"><i className="bi bi-bell"></i> <em></em></a>
                                </li> */}

                                <li className="nav-item login-drop dropdown">
                                    <a className="nav-link login dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"><img src={ details.image } /> </a>
                                    <ul className="dropdown-menu dropdown-rgt" aria-labelledby="navbarDropdown" data-popper="none">
                                        <li><Link to="/profile" className="dropdown-item">Profile</Link></li>
                                        <li><Link to="/change-password" className="dropdown-item">Change Password</Link></li>
                                        <li><a href="#" className="dropdown-item" onClick={ Logout }> Logout</a> </li>
                                    </ul>

                                </li>
                            </ul>
                        </div>
                    </nav>
                </header>
                { props.children }
            </div>
        </>

    );
};

export default Layout;
