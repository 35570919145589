import { useEffect, useState } from "react";
import getApiCall from "../../Services/getApiCall";

import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import 'rsuite/Pagination/styles/index.css';
import { Pagination } from 'rsuite';


const DataList = () => {
    const [records, setRecords] = useState([])
	const [page, setPage] = useState(1)
	const [totalPage, setTotalPage] = useState(1)
	const [totalLength, setTotalLength] = useState(0)

    useEffect(() => {
        async function getData() {
            let docs = await getApiCall("/admin/newsletters", {page}, true);
            if (docs.status) {
				setPage(docs.page)
                setTotalPage(docs.total)
                // data binding

                let records = docs.data.map((el, i) => {
                    return <tr>
                        <td scope="row">{ i + 1 }</td>
                        <td>{ el.email }</td>
                        
                    </tr>
                });

                setRecords(records);
				setTotalLength(docs.total * 10)
            } else {
                toast.error(docs.message != undefined ? docs.message : "Network error!!");
                setRecords([]);
            }
        }
        getData()

    }, []);
	
	
    const getRecords = async (page) => {

        let docs = await getApiCall("/admin/newsletters", { page }, true);
        
        if (docs.status) {
            setPage(docs.page)
            setTotalPage(docs.total)
            setTotalLength(docs.total * 10)

            // data binding

            let records = docs.data.map((el, i) => {
                return  <tr>
                            <td scope="row">{i + 1}</td>
                            <td>{el.email}</td>
                        </tr>
            });

            setRecords(records);
        } else {
            toast.error(docs.message != undefined ? docs.message : "Network error!!")
            setRecords([]);
        }
    }

	const pagination = async (page) => {
        await getRecords(page)
    }

  
    return (
        <>
            <div className="row">
                <div className="col-7 col-sm-8 col-md-8 col-lg-6">
                    <div className="page-header">
                        <h4>Newsletter Management</h4>
                    </div>
                </div>
                <div className="col-5 col-sm-4 col-md-4 col-lg-6 form-box form-filter text-end">
                    {/* <div className="btn-bg d-inline-block">
                        <Link to="/cms/add" className="btn btn-sumbit btn-primary"> Add Page</Link>
                    </div> */}
                </div>
            </div>

            <div className="table-card">
                <div className="table-reponsive">
                    <table className="table table-white">
                        <thead>
                            <tr className="table-tr-light">
                                <th scope="col" className="text-uppercase">Sr.No </th>
                                <th scope="col" className="text-uppercase">Email</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (records.length > 0)
                                ?
                                records
                                :
                                'No result found'
                            }
                        </tbody>
                    </table>
                </div>
            </div>
			<nav aria-label="Page navigation example">
                <Pagination total={totalLength} limit={10} activePage={page} prev last next first onChangePage={pagination} className="pagination justify-content-center navigation-number" />
            </nav>
        </>
    );
};

export default DataList;