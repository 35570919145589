import { useEffect, useState } from "react";
import getApiCall from "../../Services/getApiCall";

import { toast, ToastContainer } from 'react-toastify';
import { Pagination } from 'rsuite';
import moment from 'moment';
import $ from 'jquery';
import 'rsuite/Pagination/styles/index.css';


const DataList = (props) => {

    const [records, setRecords] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [totalLength, setTotalLength] = useState(0);

    useEffect(() => {

        async function getData() {
            let data = await getApiCall('/admin/get-notification', { page }, true);
            if (data.status) {
                setPage(data.page);
                setTotalPage(data.total);

                // data binding
                let records = data.data.map((el, i) => {
                    return  <tr>
                                <td scope="row">{ i + 1 }</td>
								<td>{ el.panel_type }</td>
								<td>{ el.user_type }</td>
                                <td>{ el.title }</td>
                                <td>{ el.description }</td>
                                <td>{ moment(el.createdAt).format('DD-MM-YYYY') }</td>
                            </tr>
                });
                setRecords(records);
                setTotalLength(data.total * 10);
            }
            else {
                toast.error(data.message != undefined ? data.message : 'Network error!!');
                setRecords([]);
            }
        }
        getData()

    }, []);

    const getRecords = async (page) => {
        let data = await getApiCall('/admin/get-notification', { page }, true);
        if (data.status) {
            setPage(data.page);
            setTotalPage(data.total);

            // data binding
            let records = data.data.map((el, i) => {
                return  <tr>
                            <td scope="row">{ i + 1 }</td>
							<td>{ el.panel_type }</td>
							<td>{ el.user_type }</td>
                            <td>{ el.title }</td>
                            <td>{ el.description }</td>
                            <td>{ moment(el.createdAt).format('DD-MM-YYYY') }</td>
                        </tr>
            });
            setRecords(records);
            setTotalLength(data.total * 10);
        }
        else {
            toast.error(data.message != undefined ? data.message : 'Network error!!');
            setRecords([]);
        }
    }

    const pagination = async (page) => {
        await getRecords(page)
    }

    return (
        <>

            <div>
                <div className="dashboardMain">
                    <div className="page-header">
                        <h4>Notification Management</h4>
                    </div>

                    <div className="table-card">
                        <div className="table-reponsive">
                            <table className="table table-white">
                                <thead>
                                    <tr className="table-tr-light">
                                        <th scope="col" className="text-uppercase">Sr.No </th>
                                        <th scope="col" className="text-uppercase">Panel Type</th>
										<th scope="col" className="text-uppercase">User Type</th>
										<th scope="col" className="text-uppercase">Title</th>
                                        <th scope="col" className="text-uppercase">Description</th>
                                        <th scope="col" className="text-uppercase" width="10%">Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { (records.length > 0) ? records : 'No result found' }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <nav aria-label="Page navigation example">
                <Pagination total={totalLength} limit={10} activePage={page} prev last next first onChangePage={pagination} className="pagination justify-content-center navigation-number" />
            </nav>

        </>
    )

}

export default DataList;