import { useEffect, useState } from "react";
import postApiCall from "../../Services/postApiCall";
import putApiCall from "../../Services/putApiCall";
import patchApiCall from "../../Services/patchApiCall";
import deleteApiCall from "../../Services/deleteApiCall";
import getApiCall from "../../Services/getApiCall";

import { toast, ToastContainer } from 'react-toastify';
import { Pagination } from 'rsuite';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'rsuite/Pagination/styles/index.css';


const DataList = (props) => {

    const [records, setRecords] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [totalLength, setTotalLength] = useState(0);
    const [status, setStatus] = useState('');
    const [details, setDetails] = useState({});
    const [request, setRequestParms] = useState({});

    useEffect(() => {
        async function getData() {
            let data = await postApiCall('/admin/banner-list', { page, status }, true);
            if (data.status) {
                setPage(data.page);
                setTotalPage(data.total);

                // data binding
                let records = data.data.map((el, i) => {
                    return  <tr>
                                <td scope="row">{ i + 1 }</td>
                                <td>{ el.bannerName }</td>
                                <td><img className="me-2 td-user" src={ el.bannerImage } /></td>
                                <td>{ el.description }</td>
                                <td className="text-end">
                                    {
                                        (el.isActive)
                                            ?
                                            <a href="#" className="btn btn-sm btn-green btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check-white.svg" /></a>
                                            :
                                            <a href="#" className="btn btn-sm btn-light btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check.svg" /></a>
                                    }
                               
                                    <a href="#AddbannerModal" data-bs-toggle="modal" className="btn btn-light btn-sm btn-icon m-1"><img src="images/edit.png" onClick={ ()=> { getDetails(el._id) } }/></a>
                                    <button type="button" className="btn btn-light btn-sm btn-icon  m-1"><img src="images/trash.svg" onClick={ ()=> { isDeleted(el._id) } }/></button>
                                </td>
                            </tr>
                });
                setRecords(records);
                setTotalLength(data.total * 10);
            }
            else {
                toast.error(data.message != undefined ? data.message : 'Network error!!');
                setRecords([]);
            }
        }
        getData()

    }, []);

    const getRecords = async (page, status) => {
        let data = await postApiCall('/admin/banner-list', { page, status }, true);
        if (data.status) {
            setPage(data.page);
            setTotalPage(data.total);

            // data binding
            let records = data.data.map((el, i) => {
                return  <tr>
                            <td scope="row">{ i + 1 }</td>
                            <td>{ el.bannerName }</td>
                            <td><img className="me-2 td-user" src={ el.bannerImage } /></td>
                            <td>{ el.description }</td>
                            <td className="text-end">
                                {
                                    (el.isActive)
                                        ?
                                        <a href="#" className="btn btn-sm btn-green btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check-white.svg" /></a>
                                        :
                                        <a href="#" className="btn btn-sm btn-light btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check.svg" /></a>
                                }
                                <a href="#AddbannerModal" data-bs-toggle="modal" className="btn btn-light btn-sm btn-icon m-1"><img src="images/edit.png" onClick={ ()=> { getDetails(el._id) } }/></a>
                                <button type="button" className="btn btn-light btn-sm btn-icon  m-1"><img src="images/trash.svg" onClick={ ()=> { isDeleted(el._id) } }/></button>
                            </td>
                        </tr>
            });
            setRecords(records);
            setTotalLength(data.total * 10);
        }
        else {
            toast.error(data.message != undefined ? data.message : 'Network error!!');
            setRecords([]);
        }
    }

    // change status

    async function isActive(id) {
        let data = await patchApiCall('/admin/banner/change-status/' + id, {}, true);
        if (data.status) {
            toast.success(data.message);
            getRecords(page, status);
        }
        else {
            toast.error(data.message != undefined ? data.message : 'Network error!!');
        }
    }

    // call delete function 

    async function isDeleted(id) {
        if (window.confirm("Are you sure you want to delete this banner?")) {
            let data = await deleteApiCall("/admin/banner/delete/" + id, {}, true);
            if (data.status) {
                toast.warning(data.message)
                getRecords(page, status)
            } else {
                toast.error(data.message != undefined ? data.message : "Network error!!")
            }
        }
    }

    const setRequest = (name, e) => {
        setRequestParms({ ...request, [name]: e.target.value })
    }

    const handleImageChange = (e) => {
        setRequestParms({ ...request, ['image']: e.target.files[0] })
    }

    const createNewContent = async (e) => {
        e.preventDefault();
        let data = {};

        if(details.bannerName) {
            let formData = new FormData;
            formData.append('bannerName', (request.bannerName) ? request.bannerName : details.bannerName);
            formData.append('description', (request.description) ? request.description : details.description);

            if(request.image) {
                formData.append('image', request.image, request.image.name);
            }
            
            data = await putApiCall(`/admin/edit-banner/${details._id}`, formData, true);
        }
        else {
            let formData = new FormData;
            formData.append('bannerName', (request.bannerName) ? request.bannerName : '');
            formData.append('description', (request.description) ? request.description : '');
            if(request.image) {
                formData.append('image', request.image, request.image.name);
            }
            data = await postApiCall('/admin/create-banner', formData, true);
        }

        if(data.status) {
            toast.success(data.message);
            window.location.reload(false);
        }
        else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    async function getDetails(id) {
        let data = await getApiCall("/admin/banner/details/" + id, {}, true);
        if (data.status) {
            toast.success(data.message)
            setDetails(data.data)
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    // get job details by id

    const pagination = async (page) => {
        await getRecords(page)
    }

    const filterData = async () => {
        await getRecords(0, status);
    }

    const clearFilter = () => {
        window.location.reload(false);
    }

    const clearDetails = () => {
        setDetails({});
    }

    return (
        <>

            <div className="page-header">
                <h4>Banner Management</h4>
            </div>

            <div className="form-box form-filter">
                <div className="row">
                    <div className="col-12 col-sm-4 col-md-4 col-lg-3">
                        <div className="form-group">
                            <select className="form-control form-ctrl" onChange={ (e)=> { setStatus(e.target.value) }}>
                                <option>Select Active / In-active</option>
                                <option id="1" value="true">Active</option>
                                <option id="2" value="false">In-Active</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-7 col-sm-4 col-md-4 col-lg-3">
                        <div className="btn-bg justify-content-between d-flex">
                            <a href="#" className="btn me-2 btn-sumbit btn-primary btn-block" onClick={ filterData }>Filter</a>
                            <a href="#" className="btn btn-sumbit btn-light btn-block" onClick={ clearFilter }>Reset</a> 
                        </div>
                    </div>
                    <div className="col-5 col-sm-4 col-md-4 col-lg-6 text-end">
                        <div className="btn-bg d-inline-block">
                            <a href="#AddbannerModal" data-bs-toggle="modal" className="btn btn-sumbit btn-success" onClick={ clearDetails }> Add New Banner</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="table-card">
                <div className="table-reponsive">
                    <table className="table table-white">
                        <thead>
                            <tr className="table-tr-light">
                                <th scope="col" className="text-uppercase">Sr.No </th>
                                <th scope="col" className="text-uppercase">Banner name</th>
                                <th scope="col" className="text-uppercase">Image</th>
                                <th width="40%" scope="col" className="text-uppercase">Text</th>
                                <th width="15%" scope="col" className="text-uppercase text-end">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (records.length > 0)
                                ?
                                records
                                :
                                'No result found'
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Job Details modal End */}

            <nav aria-label="Page navigation example">
                <Pagination total={totalLength} limit={10} activePage={page} prev last next first onChangePage={pagination} className="pagination justify-content-center navigation-number" />
            </nav>

            {/* Banner modal Start */}

            <div className="modal fade" id="AddbannerModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center border-0 text-center">
                            <h4 className="modal-title w-100">{ (details.bannerName) ? 'Edit' : 'Add' } Banner</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-box">
                                <form onSubmit={ createNewContent }>
                                    <div className="login-white-box shadow-none pt-1">
                                        <div className="form-group">
                                            <label>Title</label>
                                            <input type="text" className="form-control form-ctrl" placeholder="Enter" defaultValue={ details.bannerName } onChange={ (e)=> { setRequest('bannerName', e) } }/>
                                        </div>
                                        <div className="form-group">
                                            <label>Description </label>
                                            <textarea rows="4" className="form-control form-ctrl" placeholder="Enter" defaultValue={ details.description } onChange={ (e)=> { setRequest('description', e) } }></textarea>
                                        </div>
                                        <div className="form-group">
                                            <label>Upload  New Banner Image</label>
                                            <div className="upload-doc-item Upload-plus">
                                                <div className="image-upload">
                                                    <input type="file" name="" id="logo" onchange="" onChange={ handleImageChange }/>
                                                    <label for="logo" className="upload-field" id="file-label">
                                                        <div className="file-thumbnail">
                                                            <img id="file-image-preview" src="images/upload-blue.png" alt=""/><br/>
                                                            <h6 id="filename">Upload Banner Image</h6>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            (details.bannerImage)
                                            ?
                                                <div className="form-group">
                                                    <label>Previous Image</label>
                                                    <img src={ details.bannerImage } alt="banner-image"/>
                                                </div>
                                            :
                                                null
                                        }
                                        <div className="btn-width m-auto">
                                            <button type="submit" className="btn btn-block btn-normal btn-primary">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Banner modal End */}

        </>
    )

}

export default DataList;