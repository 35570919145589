import Schema from './Schema'

export const loginVailidator = (request) => {
    let result = Schema.login.validate(request);
    if(result.error) return { status:false , msg: result.error.details[0].message}  
    return { status: true}
}

export const verifyOTPVailidator = (request) => {
    let result = Schema.verifyOTP.validate(request);
    if(result.error) return { status:false , msg: result.error.details[0].message}  
    return { status: true}
}

export const forgotPasswordVailidator = (request) => {
    let result = Schema.forgotPassword.validate(request);
    if(result.error) return { status:false , msg: result.error.details[0].message}  
    return { status: true}
}

export const sendOTPVailidator = (request) => {
    let result = Schema.sendOTP.validate(request);
    if(result.error) return { status:false , msg: result.error.details[0].message}  
    return { status: true}
}

export const forgotOTPverifyVailidator = (request) => {
    let result = Schema.forgotOTPverify.validate(request);
    if(result.error) return { status:false , msg: result.error.details[0].message}  
    return { status: true}
}

export const setPasswordPVailidator = (request) => {
    let result = Schema.setPassword.validate(request);
    if(result.error) return { status:false , msg: result.error.details[0].message}  
    return { status: true}
}