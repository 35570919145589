import { useEffect, useState } from "react";
import postApiCall from "../../Services/postApiCall";
import patchApiCall from "../../Services/patchApiCall";
import deleteApiCall from "../../Services/deleteApiCall";
import getApiCall from "../../Services/getApiCall";

import { toast, ToastContainer } from "react-toastify";
import { Pagination } from 'rsuite';
import { Link } from "react-router-dom";
import moment from 'moment';
import 'rsuite/Pagination/styles/index.css';

const JOB_TYPE = {
    PART_TIME: "Part Time",
    PERMANENT: "Permanent",
    FREELANCER: "Freelance",
    INTERNSHIP: "Internship",
    FULL_TIME: 'Full Time'
}


const DataList = () => {
    const [records, setRecords] = useState([])
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [totalLength, setTotalLength] = useState(0)
    const [JobStatus, setJobStatus] = useState('ONGOING')
    const [JobDetails, setJobDetails] = useState({})
    const [companyOptions, setCompanies] = useState([])
    const [companyId, setCompanyId] = useState('')

    useEffect(() => {
        async function getData(JobStatus) {
            let docs = await postApiCall("/admin/jobs", { JobStatus }, true);
            if (docs.status) {
                setPage(docs.page)
                setTotalPage(docs.total)

                // data binding

                let records = docs.data.map((el, i) => {
                    return <tr>
                        <td scope="row">{i + 1}</td>
                        <td>{el.jobTitle}</td>
                        <td>{JOB_TYPE[el.jobType]}</td>
                        <td>{el.companies.companyName}</td>
                        <td>{`${el.salaryMinRange} - ${el.salaryMaxRange} LPA`} </td>
                        <td>{moment(el.createdAt).format('DD-MM-YYYY')}</td>

                        {
                            (JobStatus === 'COMPLETED')
                                ?
                                <>
                                    <td>{moment(el.CompletionDate).format('DD-MM-YYYY')}</td>
                                    <td>{el.location}</td>
                                </>

                                :
                                null
                        }
						
						<td className="text-end">
							{
								(el.feature)
									?
									<a href="#" className="btn btn-sm btn-green btn-icon m-1" onClick={() => { feature(el._id) }}><img src="images/check-white.svg" /></a>
									:
									<a href="#" className="btn btn-sm btn-light btn-icon m-1" onClick={() => { feature(el._id) }}><img src="images/check.svg" /></a>
							}
						</td>

                        <td className="text-end">
                            {
                                (JobStatus === 'ONGOING')
                                    ?
                                    <>
                                        {
                                            (el.isActive)
                                                ?
                                                <a href="#" className="btn btn-sm btn-green btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check-white.svg" /></a>
                                                :
                                                <a href="#" className="btn btn-sm btn-light btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check.svg" /></a>
                                        }

                                        <button type="button" className="btn btn-danger btn-sm btn-icon  m-1" onClick={() => { isDeleted(el._id) }}><img src="images/cross-white-x.svg" /></button>
                                        <Link to={`/view-applied-condidates/${el._id}`} className="btn btn-light btn-sm btn-icon m-1"><img src="images/file-text.svg" /></Link>
                                        <a href="#JobDetailsModal" data-bs-toggle="modal" className="btn btn-sm btn-light btn-icon m-1" onClick={() => { getJobDetails(el._id) }}><img src="images/eye.svg"/></a>
                                    </>
                                    :
                                    <Link to={`/view-applied-condidates/${el._id}`} className="btn btn-light btn-sm btn-icon m-1"><img src="images/file-text.svg" /></Link>
                            }


                        </td>
                    </tr>
                });

                setRecords(records);

                setTotalLength(docs.total * 10)
            } else {
                toast.error(docs.message != undefined ? docs.message : "Network error!!")
                setRecords([]);
            }
        }
        getData(JobStatus)

        // call get company list function
        getCompanyList()

    }, [JobStatus]);

    const getRecords = async (page, JobStatus, companies) => {

        let docs = await postApiCall("/admin/jobs", { page, JobStatus, companies }, true);

        if (docs.status) {
            setPage(docs.page)
            setTotalPage(docs.total)
            setTotalLength(docs.total * 10)

            // data binding

            let records = docs.data.map((el, i) => {
                return <tr>
                    <td scope="row">{i + 1}</td>
                    <td>{el.jobTitle}</td>
                    <td>{JOB_TYPE[el.jobType]}</td>
                    <td>{el.companies.companyName}</td>
                    <td>{`${el.salaryMinRange} - ${el.salaryMaxRange} LPA`}</td>
                    <td>{moment(el.createdAt).format('DD-MM-YYYY')}</td>

                    {
                        (JobStatus === 'COMPLETED')
                            ?
                            <>
                                <td>{moment(el.CompletionDate).format('DD-MM-YYYY')}</td>
                                <td>{el.location}</td>
                            </>

                            :
                            null
                    }
					
					<td className="text-end">
                        {
							(el.feature)
								?
								<a href="#" className="btn btn-sm btn-green btn-icon m-1" onClick={() => { feature(el._id) }}><img src="images/check-white.svg" /></a>
								:
								<a href="#" className="btn btn-sm btn-light btn-icon m-1" onClick={() => { feature(el._id) }}><img src="images/check.svg" /></a>
						}
                    </td>

                    <td className="text-end">
                        {
                            (JobStatus === 'ONGOING')
                                ?
                                <>
                                    {
                                        (el.isActive)
                                            ?
                                            <a href="#" className="btn btn-sm btn-green btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check-white.svg" /></a>
                                            :
                                            <a href="#" className="btn btn-sm btn-light btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check.svg" /></a>
                                    }
                                    <button type="button" className="btn btn-danger btn-sm btn-icon  m-1" onClick={() => { isDeleted(el._id) }}><img src="images/cross-white-x.svg" /></button>
                                    <Link to={`/view-applied-condidates/${el._id}`} className="btn btn-light btn-sm btn-icon m-1"><img src="images/file-text.svg" /></Link>
                                    <a href="#JobDetailsModal" data-bs-toggle="modal" className="btn btn-sm btn-light btn-icon m-1" onClick={() => { getJobDetails(el._id) }}><img src="images/eye.svg"/></a>
                                </>
                                :
                                <Link to={`/view-applied-condidates/${el._id}`} className="btn btn-light btn-sm btn-icon m-1"><img src="images/file-text.svg" /></Link>
                        }
                    </td>
                </tr>
            });

            setRecords(records);
        } else {
            toast.error(docs.message != undefined ? docs.message : "Network error!!");
            setRecords([]);
        }
    }

    // call change status function

    async function isActive(id) {
        let data = await patchApiCall("/admin/jobs/" + id, {}, true);
        if (data.status) {
            toast.warning(data.message)
            getRecords(page, JobStatus)
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }
	
	async function feature(id) {
        let data = await patchApiCall("/admin/jobs/feature/" + id, {}, true);
        if (data.status) {
            toast.warning(data.message)
            getRecords(page, JobStatus)
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    // call delete function 

    async function isDeleted(id) {
        if (window.confirm("Are you sure you want to delete this job?")) {
            let data = await deleteApiCall("/admin/jobs/" + id, {}, true);
            if (data.status) {
                toast.warning(data.message)
                getRecords(page, JobStatus)
            } else {
                toast.error(data.message != undefined ? data.message : "Network error!!")
            }
        }
    }

    async function getJobDetails(id) {
        let data = await getApiCall("/admin/jobs/" + id, {}, true);
        if (data.status) {
            toast.warning(data.message)
            setJobDetails(data.data)
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    // get company list

    async function getCompanyList() {
        let data = await getApiCall("/admin/common/company", {}, true);
        if (data.status) {
            let companies = data.data.map(el => {
                return <option id={ el._id } value={ el._id }>{ el.companyName }</option>
            });
            setCompanies(companies);
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    // get job details by id

    const pagination = async (page) => {
        await getRecords(page, JobStatus)
    }

    const filterByCompany = async () => {
        if(companyId.length === 24) {
            await getRecords(0, JobStatus, companyId);
        }
    }

    const resetFilter = async (e) => {
        window.location.reload(false);
    }

    const exportData = async () => {
        let data = await getApiCall('/admin/exportJobsData', {}, true);
        if(data.status) {
            var csvString = data.data;
            var universalBOM = "\uFEFF";
            var a = window.document.createElement('a');
            a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM+csvString));
            a.setAttribute('download', 'jobs.csv');
            window.document.body.appendChild(a);
            a.click();
            window.location.reload();
        }
        else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    return (
        <>
            <ToastContainer/>
            <div className="page-header">
                <h4>Job Management</h4>
            </div>
            <div className="row">
                <div className="col-12 col-sm-6 col-md-5 col-lg-4">
                    <ul className="nav nav-pills nav-justified tabs-nav bg-white">
                        <li className="nav-item">
                            <a className="nav-link active" data-bs-toggle="tab" href="#OngoingTab" onClick={ ()=> { setJobStatus('ONGOING') } }>Ongoing</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-bs-toggle="tab" href="#CompletedTab" onClick={ ()=> { setJobStatus('COMPLETED') } }>Completed </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="form-box form-filter">
                <div className="row">
                    <div className="col-12 col-sm-4 col-md-3 col-lg-2">
                        <div className="form-group">
                            <select className="form-control form-ctrl" onChange={ (e)=> { setCompanyId(e.target.value) }}>
                                <option>Company</option>
                                <option>Select</option>
                                { companyOptions }
                            </select>
                        </div>
                    </div>

                    <div className="col-7 col-sm-4 col-md-3 col-lg-2">
                        <div className="btn-bg justify-content-between d-flex">
                            <a href="#" className="btn me-2 btn-sumbit btn-primary btn-block" onClick={ filterByCompany }>Filter</a>
                            <a href="#" className="btn btn-sumbit btn-light btn-block" onClick={ resetFilter }>Reset</a>
                        </div>
                    </div>
                    <div className="col-5 col-sm-4 col-md-6 col-lg-8 text-end">
                        <div className="btn-bg d-inline-block">
                            <a href="#" className="btn btn-sumbit btn-success" onClick={ exportData }>Export to Excel</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane active" id="OngoingTab" role="tabpanel" aria-labelledby="home-tab">
                    <div className="table-card">
                        <div className="table-reponsive">
                            <table className="table table-white">
                                <thead>
                                    <tr className="table-tr-light">
                                        <th scope="col" className="text-uppercase">Sr.No </th>
                                        <th scope="col" className="text-uppercase">Job Title</th>
                                        <th scope="col" className="text-uppercase">Job Types</th>
                                        <th scope="col" className="text-uppercase">Company name</th>
                                        <th scope="col" className="text-uppercase">Salary Range</th>
										<th scope="col" className="text-uppercase">Job uploaded on date</th>
                                        {
                                            (JobStatus === 'COMPLETED')
                                                ?
                                                <>
                                                    <th scope="col" className="text-uppercase">Job completed on</th>
                                                    <th scope="col" className="text-uppercase">Job Location</th>
                                                </>
                                                :
                                                null
                                        }
										<th scope="col" className="text-uppercase">Featured</th>
                                        <th scope="col" className="text-uppercase text-end">Action</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        (records.length > 0)
                                        ?
                                        records
                                        :
                                        'No result found'
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/* Job Details modal Start */}

            <div className="modal fade" id="JobDetailsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center border-0 text-center">
                            <h4 className="modal-title w-100">Job Details</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-box viewdetail">
                                <div className="login-white-box shadow-none pt-1">

                                    <div className="row">
                                        <div className="col-6 col-sm-4 col-md-4">
                                            <div className="profile-info">
                                                <p className="text-uppercase">Job ID</p>
                                                <h5 className="mb-0">{ JobDetails.jobId }</h5>
                                            </div>
                                        </div>
                                        <div className="col-6 col-sm-4 col-md-4">
                                            <div className="profile-info">
                                                <p className="text-uppercase">opening name</p>
                                                <h5 className="mb-0">{ JobDetails.jobTitle }</h5>
                                            </div>
                                        </div>
                                        <div className="col-6 col-sm-4 col-md-4">
                                            <div className="profile-info">
                                                <p className="text-uppercase">Location</p>
                                                <h5 className="mb-0">{ JobDetails.location }</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 col-sm-6 col-md-6">
                                            <div className="profile-info">
                                                <p className="text-uppercase">Number Of Openings</p>
                                                <h5 className="mb-0">{ JobDetails.numberOfOpening }</h5>
                                            </div>
                                        </div>

                                        <div className="col-6 col-sm-6 col-md-6">
                                            <div className="profile-info">
                                                <p className="text-uppercase">Experience needed</p>
                                                <h5 className="mb-0">{ `${ JobDetails.experienceMinRange } - ${ JobDetails.experienceMaxRange } Year` }</h5>
                                            </div>
                                        </div>


                                        <div className="col-6 col-sm-6 col-md-6">
                                            <div className="profile-info">
                                                <p className="text-uppercase">Job Type</p>
                                                <h5 className="mb-0">{ JOB_TYPE[JobDetails.jobType] }</h5>
                                            </div>
                                        </div>
                                        <div className="col-6 col-sm-6 col-md-6">
                                            <div className="profile-info">
                                                <p className="text-uppercase">Salary</p>
                                                <h5 className="mb-0">₹ { `${ JobDetails.salaryMinRange } - ${ JobDetails.salaryMaxRange }` }/LPA</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="profile-info">
                                        <p className="text-uppercase">Job Details:</p>
                                        <h5>{ JobDetails.description }</h5>
                                    </div>
                                    <div className="profile-info">
                                        <p className="text-uppercase">Roles Responsibilities:</p>
                                        <h5>{ JobDetails.rolesResponsibilities }</h5>
                                    </div>
                                    <div className="btn-width m-auto">
                                        <button type="button" className="btn btn-block btn-normal btn-primary" data-bs-dismiss="modal" aria-label="Close">Close</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* Job Details modal End */}

            <nav aria-label="Page navigation example">
                <Pagination total={totalLength} limit={10} activePage={page} prev last next first onChangePage={pagination} className="pagination justify-content-center navigation-number" />
            </nav>
        </>
    );
};

export default DataList;