import { useEffect, useState } from "react";
import postApiCall from "../../Services/postApiCall";
import getApiCall from "../../Services/getApiCall";

import { toast, ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';

import 'rsuite/Pagination/styles/index.css';


const EditProfile = (props) => {

    const [details, setDetails] = useState({});
    const [request, setRequestParms] = useState({});

    useEffect(() => {
        async function getData() {
            let data = await getApiCall('/admin/profile', {}, true);
            if (data.status) {
                setDetails(data.data);
            }
            else {
                toast.error(data.message != undefined ? data.message : 'Network error!!');
                setDetails([]);
            }
        }
        getData()

    }, []);

    const setRequest = (name, e) => {
        setRequestParms({ ...request, [name]: e.target.value })
    }

    const handleImageChange = (e) => {
        setRequestParms({ ...request, ['image']: e.target.files[0] })
    }

    const editProfile = async (e) => {
        e.preventDefault();
        let formData = new FormData;
        formData.append('userName', (request.userName) ? request.userName : details.userName);
        if (request.image) {
            formData.append('image', request.image, request.image.name);
        }

        let data = await postApiCall('/admin/profile', formData, true);

        if (data.status) {
            toast.success(data.message);
            window.location.href = '/profile';
        }
        else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    return (
        <>
            <ToastContainer />
            <div className="dashboardMain">
                <div className="page-header back-page">
                    <h4><Link to="/profile" className="back-arrow" ><img src="images/back-arrow.svg"/></Link> Edit Profile</h4>
                </div>
                <div className="account_dashboard">
                    <div className="form-box">
                        <form onSubmit={ editProfile }>
                            <div className="name_info border-0">
                                <div className="profile-pic upload-box">
                                    <div className="upload-doc-item">
                                        <div className="image-upload">
                                            <input type="file" name="" id="logo" onChange={ (e) => { handleImageChange(e) } }/>
                                                <label for="logo" className="upload-field" id="file-label">
                                                    <div className="file-thumbnail">
                                                        <img id="file-image-preview" src={ details.image } alt=""/>
                                                        <h6 id="filename"></h6>
                                                        <div className="edit-pic"><img src="images/upload.png"/></div>
                                                    </div>
                                                </label>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                    <div className="form-group">
                                        <label>User Name</label>
                                        <input type="text" className="form-ctrl form-control" placeholder="Enter your user name" defaultValue={ details.userName } onChange={ (e)=> { setRequest('userName', e) } }/>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-center mt-5">
                                <div className="col-12 col-sm-4 col-md-3 col-lg-2">
                                    <button type="submit" className="btn me-2 btn-sumbit btn-primary btn-block">Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )

}

export default EditProfile;