import Joi from 'joi'

const login = Joi.object().keys({
    email: Joi.string().email({ minDomainSegments: 2, tlds: { allow: ['com', 'net',"ie"] } }).required(),
    password: Joi.string().required(),
});

const verifyOTP = Joi.object().keys({
    _id: Joi.string().required(),
    otp: Joi.string().required(),
});

const forgotPassword = Joi.object().keys({
    email: Joi.string().email({ minDomainSegments: 2, tlds: { allow: ['com', 'net',"ie"] } }).required(),
});

const sendOTP = Joi.object().keys({
    email: Joi.string().email({ minDomainSegments: 2, tlds: { allow: ['com', 'net', `ie`] } }).required(),
});

const forgotOTPverify = Joi.object().keys({
    _id: Joi.string().required(),
    otp: Joi.string().required(),
});

const setPassword = Joi.object().keys({
    _id: Joi.string().required(),
    password: Joi.string().required(),
    cof_password: Joi.string().valid(Joi.ref('password')).required(),
});


export default {
    login,
    verifyOTP,
    forgotPassword,
    sendOTP,
    forgotOTPverify,
    setPassword
}
