import { useEffect, useState } from "react";
import getApiCall from "../../Services/getApiCall";
import { toast, ToastContainer } from "react-toastify";
import { Pagination } from 'rsuite';
import { Link } from "react-router-dom";
import moment from 'moment';
import 'rsuite/Pagination/styles/index.css';

const ViewAppliedCondidate = (props) => {
    let id = props.match.params.id;
    const [records, setRecords] = useState([])
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [totalLength, setTotalLength] = useState(0)

    useEffect(() => {
        async function getData(id) {
            let docs = await getApiCall("/admin/jobs/applied/" + id, {}, true);
            if (docs.status) {
                setPage(docs.page)
                setTotalPage(docs.total)
                setTotalLength(docs.total * 10)

                let records = docs.data.map((el, i) => {
                    return <tr>
                        <td scope="row">
                            { i + 1 }
                        </td>
                        <td> { el.jobseekers.fullName } <br /><span className="small">{ el._id }</span></td>
                        <td>+91 { el.jobseekers.mobile }</td>
                        <td>{ el.jobseekers.email }</td>
                        <td>{ el.jobseekers.currenctLocationObject.name }, { el.jobseekers.currenctLocationObject.stateName }</td>
                        <td>{ moment(el.createdAt).format('DD-MM-YYYY') }</td>
                        <td className="text-end">
                            {/* <a href="#ResumeModal" data-bs-toggle="modal" className="btn btn-light btn-sm btn-icon m-1"><img src="/images/file-text.svg" /></a> */}
                            <a href={el.resumeLink} target="_blank" className="btn btn-light btn-sm btn-icon m-1"><img src="/images/file-text.svg" /></a>
                        </td>
                    </tr>
                })

                setRecords(records);
            } else {
                toast.error(docs.message != undefined ? docs.message : "Network error!!")
            }
        }
        getData(id)
    }, []);

    const getRecords = async (page, id) => {
        let docs = await getApiCall("/admin/jobs/employer/" + id, { page }, true);
        if (docs.status) {
            setPage(docs.page)
            setTotalPage(docs.total)
            setTotalLength(docs.total * 10)
            setRecords(docs.data)
        } else {
            toast.error(docs.message != undefined ? docs.message : "Network error!!")
        }
    }

    const pagination = async (page, id) => {
        await getRecords(page)
    }

    const exportData = async () => {
        let data = await getApiCall(`/admin/jobs/applied/${id}?exportData=1`, {}, true);
        if(data.status) {
            var csvString = data.data;
            var universalBOM = "\uFEFF";
            var a = window.document.createElement('a');
            a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM+csvString));
            a.setAttribute('download', 'appliedcondidates.csv');
            window.document.body.appendChild(a);
            a.click();
            window.location.reload();
        }
        else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    return (
        <>
            <div className="form-box form-filter">
                <div className="row">
                    <div className="col-7 col-sm-8 col-md-8 col-lg-8">
                        <div className="page-header back-page">
                            <h4><Link className="back-arrow" to="/job-management"><img src="/images/back-arrow.svg" /></Link> View Applied Candidates</h4>
                        </div>
                    </div>
                    <div className="col-5 col-sm-4 col-md-4 col-lg-4 text-end">
                        <div className="btn-bg d-inline-block">
                            <a href="#" className="btn btn-sumbit btn-success" onClick={ exportData }>Export to Excel</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="table-card">
                <div className="table-reponsive">
                    <table className="table table-white">
                        <thead>
                            <tr className="table-tr-light">
                                <th scope="col" className="text-uppercase">Sr.No </th>
                                <th scope="col" className="text-uppercase">Candidate Name & ID</th>
                                <th scope="col" className="text-uppercase">Mobile</th>
                                <th scope="col" className="text-uppercase">Email ID</th>
                                <th scope="col" className="text-uppercase">Location</th>
                                <th scope="col" className="text-uppercase">Applied on date</th>
                                <th scope="col" className="text-uppercase text-end">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            { records }
                        </tbody>

                    </table>
                </div>
            </div>

            <nav aria-label="Page navigation example">
                <Pagination total={totalLength} limit={10} activePage={page} prev last next first onChangePage={pagination} className="pagination justify-content-center navigation-number" />
            </nav>

            {/* Resume modal Start */}

            <div className="modal" id="ResumeModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">

                        <div className="modal-body">
                            <img src="/images/riched-img.png" className="img-fluid m-auto" />
                            <div className="form-box mt-4">
                                <div className="row justify-content-center">
                                    <div className="col-6 col-sm-5 col-md-4 col-lg-3">
                                        <button type="button" className="btn btn-block btn-normal btn-info">Download </button>
                                    </div>
                                    <div className="col-6 col-sm-5 col-md-4 col-lg-3">
                                        <button type="button" className="btn btn-block btn-normal btn-primary" data-bs-dismiss="modal" aria-label="Close">Close</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* Resume modal End */}

        </>
    );
};

export default ViewAppliedCondidate;