import React from "react";

const ResetPassword = () => {
  return (
    <div className="login-wrap  bg-white">
      <div className="container-fluid">
        <div className="form-box-login">
          <div className="row  justify-content-center">
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <div className="login-white-box form-box bg-light">
                <div className="back-page border-0">
                  <h3 className="mb-0">
                    <a className="back-arrow" href="javascript:history.back()">
                      <img src="images/ArrowBack.svg" />
                    </a>{" "}
                    OTP Verification
                  </h3>
                </div>
                <div className="title mb-3">
                  <h4 className="mb-1">Create New Password</h4>
                  <p className="mb-0">
                    Your new password must be different from the previous you
                    used
                  </p>
                </div>
                <form action="">
                  <div className="form-group">
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon1">
                        <img src="images/lock.svg" />
                      </span>
                      <input
                        id="password-field"
                        type="password"
                        className="form-control form-ctrl border-left-right-0  ps-0"
                        name="password"
                        value=""
                        placeholder="Password"
                      />
                      <span className="input-group-text" id="basic-addon1">
                        <span
                          toggle="#password-field"
                          className="fa fa-fw field-icon toggle-password fa-eye-slash"
                        ></span>
                      </span>
                    </div>
                  </div>
                  <div className="form-group mb-5">
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon1">
                        <img src="images/lock.svg" />
                      </span>
                      <input
                        id="password-field"
                        type="password"
                        className="form-control form-ctrl border-left-right-0  ps-0"
                        name="password"
                        value=""
                        placeholder="Password"
                      />
                      <span className="input-group-text" id="basic-addon1">
                        <span
                          toggle="#password-field"
                          className="fa fa-fw field-icon toggle-password fa-eye-slash"
                        ></span>
                      </span>
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="btn btn-block btn-sumbit btn-lg btn-warning bg-gradient-warning"
                  >
                    Change Password
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
