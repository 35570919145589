import { useEffect, useState } from "react";
import postApiCall from "../../Services/postApiCall";
import patchApiCall from "../../Services/patchApiCall";
import deleteApiCall from "../../Services/deleteApiCall";
import getApiCall from "../../Services/getApiCall";

import { toast, ToastContainer } from "react-toastify";
import { Pagination } from 'rsuite';
import { Link } from "react-router-dom";
import moment from 'moment';
import 'rsuite/Pagination/styles/index.css';

const DataList = () => {
    const [records, setRecords] = useState([])
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [totalLength, setTotalLength] = useState(0)
    const [details, setDetails] = useState({})
    const [filterType, setFilterType] = useState('')
    const [countries, setContries] = useState([])
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])
    const [countryId, setCountryId] = useState('')

    useEffect(() => {
        async function getData() {
            let docs = await postApiCall("/admin/employer", { page, type: filterType }, true);
            if (docs.status) {
                setPage(docs.page)
                setTotalPage(docs.total)

                // data binding

                let records = docs.data.map((el, i) => {
                    return  <tr>
                                <td scope="row">{i + 1}</td>
                                <td>{ (el.companies === undefined) ? '' : el.companies.companyName }</td>
                                {
                                    (el.isActive)
                                    ?
                                    <td className="text-success">Active</td>
                                    :
                                    <td className="text-danger">In-Active</td>
                                }
                                <td>{moment(el.createdAt).format('DD-MM-YYYY')}</td>
                                <td>{el.jobapplications}</td>

                        
                                <td className="text-end">
                                    {
                                        (el.isActive)
                                        ?
                                        <a href="#" className="btn btn-sm btn-green btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check-white.svg" /></a>
                                        :
                                        <a href="#" className="btn btn-sm btn-light btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check.svg" /></a>
                                    }
                                    {
                                        (el.isVerify)
                                        ?
                                        <a href="#" className="btn btn-sm btn-green" onClick={() => { isVerify(el._id) }}>Verified</a>
                                        :
                                        <a href="#" className="btn btn-sm btn-light" onClick={() => { isVerify(el._id) }}>Un-verified</a>
                                    }
                                    {/* <button type="button" className="btn btn-danger btn-sm btn-icon  m-1" onClick={() => { isDeleted(el._id) }}><img src="images/cross-white-x.svg" /></button> */}
                                    <Link to={`/view-job-history/${el._id}`} className="btn btn-light btn-sm btn-icon m-1"><img src="images/file-text.svg" /></Link>
                                    <a href="#viewdetailModal" data-bs-toggle="modal" className="btn btn-sm btn-light btn-icon m-1" onClick={() => { getDetails(el._id) }}><img src="images/eye.svg"/></a>
                                </td>
                            </tr>
                });

                setRecords(records);

                setTotalLength(docs.total * 10)
            } else {
                toast.error(docs.message != undefined ? docs.message : "Network error!!")
                setRecords([]);
            }
        }
        getData()

        // call get country list function
        getCountryList()

    }, [filterType]);

    const getRecords = async (page, filterType, city) => {

        let docs = await postApiCall("/admin/employer", { page, 'type': filterType, city }, true);
        setFilterType(filterType);
      
        if (docs.status) {
            setPage(docs.page)
            setTotalPage(docs.total)
            setTotalLength(docs.total * 10)

            // data binding

            let records = docs.data.map((el, i) => {
                return  <tr>
                            <td scope="row">{i + 1}</td>
                            <td>{ (el.companies === undefined) ? '' : el.companies.companyName }</td>

                            {
                                (el.isActive)
                                ?
                                <td className="text-success">Active</td>
                                :
                                <td className="text-danger">In-Active</td>
                            }

                            <td>{moment(el.createdAt).format('DD-MM-YYYY')}</td>
                            <td>{el.jobapplications}</td>

                    
                            <td className="text-end">
                                {
                                    (el.isActive)
                                    ?
                                    <a href="#" className="btn btn-sm btn-green btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check-white.svg" /></a>
                                    :
                                    <a href="#" className="btn btn-sm btn-light btn-icon m-1" onClick={() => { isActive(el._id) }}><img src="images/check.svg" /></a>
                                }

                                {
                                    (el.isVerify)
                                    ?
                                    <a href="#" className="btn btn-sm btn-green" onClick={() => { isVerify(el._id) }}>Verified</a>
                                    :
                                    <a href="#" className="btn btn-sm btn-light" onClick={() => { isVerify(el._id) }}>Un-verified</a>
                                }

                                {/* <button type="button" className="btn btn-danger btn-sm btn-icon  m-1" onClick={() => { isDeleted(el._id) }}><img src="images/cross-white-x.svg" /></button> */}
                                <Link to={`/view-job-history/${el._id}`} className="btn btn-light btn-sm btn-icon m-1"><img src="images/file-text.svg" /></Link>
                                <a href="#viewdetailModal" data-bs-toggle="modal" className="btn btn-sm btn-light btn-icon m-1" onClick={() => { getDetails(el._id) }}><img src="images/eye.svg"/></a>
                            </td>
                        </tr>
            });

            setRecords(records);
        } else {
            toast.error(docs.message != undefined ? docs.message : "Network error!!")
            setRecords([]);
        }
    }

    // call change status function

    async function isActive(id) {
        let data = await patchApiCall("/admin/employer/" + id, {}, true);
        if (data.status) {
            toast.warning(data.message)
            getRecords(page, filterType)
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    async function isVerify(id) {
        let data = await patchApiCall("/admin/employer-verify/" + id, {}, true);
        if (data.status) {
            toast.warning(data.message)
            getRecords(page, filterType)
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    async function getDetails(id) {
        let data = await getApiCall("/admin/employer/" + id, {}, true);
        if (data.status) {
            toast.warning(data.message)
            setDetails(data.data)
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    // get job details by id

    const pagination = async (page) => {
        await getRecords(page, filterType)
    }

    // get country list

    async function getCountryList() {
        let data = await getApiCall('/admin/common/country', {}, true);
        if (data.status) {
            let countries = data.data.map(el => {
                return <option id={ el._id } value={ el._id }>{ el.name }</option>
            });
            setContries(countries);
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    // get state list
    
    async function getStateList(e) {
        let countryId = e.target.value;
        let data = await getApiCall(`/admin/common/state?countryId=${countryId}`, {}, true);
        if (data.status) {
            setCountryId(countryId);
            let states = data.data.map(el => {
                return <option id={ el._id } value={ el._id }>{ el.name }</option>
            });
            setStates(states);
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    // get city list

    async function getCityList(e) {
        let stateId = e.target.value;
        let data = await getApiCall(`/admin/common/city?countryId=${countryId}&stateId=${stateId}`, {}, true);
        if (data.status) {
            let cities = data.data.map(el => {
                return <option id={ el._id } value={ el._id }>{ el.name }</option>
            });
            setCities(cities);
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

    const resetFilter = async (e) => {
        window.location.reload(false);
    }

    const exportData = async () => {
        let data = await getApiCall('/admin/exportEmployerData', {}, true);
        if(data.status) {
            var csvString = data.data;
            var universalBOM = "\uFEFF";
            var a = window.document.createElement('a');
            a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM+csvString));
            a.setAttribute('download', 'employers.csv');
            window.document.body.appendChild(a);
            a.click();
            window.location.reload();
        }
        else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }


    return (
        <>
            <div className="page-header">
                <h4>Employer Management</h4>
            </div>
            <div className="row">
                <div className="col-12 col-sm-12 col-md-11 col-lg-9 col-xl-8">
                    <ul className="nav nav-pills nav-justified tabs-nav bg-white">
                        <li className="nav-item">
                            <a className="nav-link active" data-bs-toggle="tab" aria-current="page" href="#AllTab" onClick={ ()=> { setFilterType('') }}>All</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-bs-toggle="tab" onClick={ ()=> { setFilterType('VERIFIED') }} href="#">Verified</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-bs-toggle="tab" onClick={ ()=> { setFilterType('UN_VERIFIED') }} href="#">Un-Verified</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-bs-toggle="tab" onClick={ ()=> { setFilterType('ACTIVE') }} href="#">Active</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-bs-toggle="tab" onClick={ ()=> { setFilterType('INACTIVE') }} href="#">Inactive</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="form-box form-filter">
                <div className="row">
                    <div className="col-12 col-sm-4 col-md-3 col-lg-2">
                        <div className="form-group">
                            <select className="form-control form-ctrl" onChange={ (e)=> { getStateList(e) }}>
                                <option>Country</option>
                                <option>Select</option>
                                { countries }
                            </select>
                        </div>
                    </div>
                    <div className="col-12 col-sm-4 col-md-3 col-lg-2">
                        <div className="form-group">
                            <select className="form-control form-ctrl" onChange={ (e)=> { getCityList(e) }}>
                                <option>State</option>
                                <option>Select</option>
                                { states }
                            </select>
                        </div>
                    </div>
                    <div className="col-12 col-sm-4 col-md-3 col-lg-2">
                        <div className="form-group">
                            <select className="form-control form-ctrl" onChange={ (e)=> { getRecords(page, filterType, e.target.value) }}>
                                <option>City</option>
                                <option>Select</option>
                                { cities }
                            </select>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 col-md-3 col-lg-2">
                        <div className="btn-bg justify-content-between d-flex">
                            <a href="#" className="btn me-2 btn-sumbit btn-primary btn-block">Filter</a>
                            <a href="#" className="btn btn-sumbit btn-light btn-block" onClick={ resetFilter }>Reset</a>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 col-md-3 col-lg-4 text-end">
                        <div className="btn-bg d-inline-block">
                            <a href="#" className="btn btn-sumbit btn-success" onClick={ exportData }>Export to Excel</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="AllTab" role="tabpanel" aria-labelledby="home-tab">
                    <div className="table-card">
                        <div className="table-reponsive">
                            <table className="table table-white">
                                <thead>
                                    <tr className="table-tr-light">
                                        <th scope="col" className="text-uppercase">Sr.No </th>
                                        {/* <th scope="col" className="text-uppercase">Username & ID</th> */}
                                        <th scope="col" className="text-uppercase">Company Name</th>
                                        <th scope="col" className="text-uppercase">Account</th>
                                        <th scope="col" className="text-uppercase">User From</th>
                                        <th scope="col" className="text-uppercase">Total jobs created</th>
                                        <th scope="col" className="text-uppercase text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        (records.length > 0)
                                        ?
                                            records
                                        :
                                            'No result found' 
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="viewdetailModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center border-0 text-center">
                            <h4 className="modal-title w-100">View Profile</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-box viewdetail">
                                <div className="login-white-box shadow-none pt-1">

                                    <div className="row">
                                        <div className="col-6 col-sm-4 col-md-4">
                                            <div className="profile-info">
                                                <p className="text-uppercase">Company Name</p>
                                                <h5 className="mb-0">{ (details.companies) ? details.companies.companyName : '' }</h5>
                                            </div>
                                        </div>
                                        <div className="col-6 col-sm-4 col-md-4">
                                            <div className="profile-info">
                                                <p className="text-uppercase">Number:</p>
                                                <h5 className="mb-0">+91 { details.mobile }</h5>
                                            </div>
                                        </div>
                                        {/* <div className="col-6 col-sm-4 col-md-4">
                                            <div className="profile-info">
                                                <p className="text-uppercase">Email ID</p>
                                                <h5 className="mb-0">abcf@gmail.com</h5>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="row">
                                        <div className="col-6 col-sm-6 col-md-6">
                                            <div className="profile-info">
                                                <p className="text-uppercase">Registered from date</p>
                                                <h5 className="mb-0">{ moment(details.createdAt).format('DD-MM-YYYY') }</h5>
                                            </div>
                                        </div>

                                        {/* <div className="col-6 col-sm-6 col-md-6">
                                            <div className="profile-info">
                                                <p className="text-uppercase">Company name</p>
                                                <h5 className="mb-0"></h5>
                                            </div>
                                        </div> */}


                                        <div className="col-6 col-sm-6 col-md-6">
                                            <div className="profile-info">
                                                <p className="text-uppercase">Total jobs created</p>
                                                <h5 className="mb-0">{ details.jobapplications }</h5>
                                            </div>
                                        </div>
                                        <div className="col-6 col-sm-6 col-md-6">
                                            <div className="profile-info">
                                                <p className="text-uppercase">Registration fees paid on date</p>
                                                <h5 className="mb-0">30/02/2023</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="btn-width m-auto">
                                        <button type="button" className="btn btn-block btn-normal btn-primary" data-bs-dismiss="modal" aria-label="Close">Close</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <nav aria-label="Page navigation example">
                <Pagination total={totalLength} limit={10} activePage={page} prev last next first onChangePage={pagination} className="pagination justify-content-center navigation-number" />
            </nav>
        </>
        );
};

export default DataList;