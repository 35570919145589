import React from "react";

function PwdForgot() {
  return (
    <div className="login-wrap  bg-white">
      <div className="container-fluid">
        <div className="form-box-login">
          <div className="row  justify-content-center">
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <div className="login-white-box form-box bg-light">
                <div className="back-page border-0">
                  <h3 className="mb-0">
                    <a className="back-arrow" href="javascript:history.back()">
                      <img src="images/ArrowBack.svg" />
                    </a>{" "}
                    Forgot Password
                  </h3>
                </div>
                <div className="title">
                  <h4 className="mb-1">Enter Email ID</h4>
                  <p>
                    Enter the email address associated with your account & we’ll
                    send an email instructions
                  </p>
                </div>
                <form action="">
                  <div className="form-group mb-7">
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon1">
                        <img src="images/mail.svg" />
                      </span>
                      <input
                        type="text"
                        className="form-control form-ctrl border-left-0  ps-0"
                        placeholder="Email ID"
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-block btn-sumbit btn-lg btn-warning bg-gradient-warning"
                  >
                    Send OTP
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PwdForgot;
