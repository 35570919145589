const ENVIRONMENT = 'DEV';

const Constant = {
    APP_NAME: "arbeit",
    'statusSuccess': 'Success',
    'statusFailure': 'Failure',
    'perPage': 10,
    "successCode": 200,
    "updateSuccessCode": 202,
}

const basePath = {
   PROD: {
      apiBasePath: "http://15.207.118.168:5130/api"
   },
   DEV:{
      apiBasePath: "https://api.arbeitnexus.com/api"
   },
   LOCAL:{
      apiBasePath: "http://localhost:5130/api"
   },
}

if (ENVIRONMENT === 'PROD')
      Constant.apiBasePath = basePath["PROD"].apiBasePath;
else if(ENVIRONMENT === 'DEV')
      Constant.apiBasePath = basePath["DEV"].apiBasePath;
else if(ENVIRONMENT === 'LOCAL')
      Constant.apiBasePath = basePath["LOCAL"].apiBasePath;

module.exports = Constant;
