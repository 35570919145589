import { useEffect, useState } from "react";
import getApiCall from "../../Services/getApiCall";
import { toast, ToastContainer } from "react-toastify";
import { Pagination } from 'rsuite';
import { Link } from "react-router-dom";
import moment from 'moment';
import 'rsuite/Pagination/styles/index.css';

const JOB_TYPE = {
    PART_TIME: "Part Time",
    PERMANENT: "Permanent",
    FREELANCER: "Freelance",
    INTERNSHIP: "Internship",
    FULL_TIME: 'Full Time'
}

const ViewJobHistory = (props) => {
    const employerId = props.match.params.id;
    const [records, setRecords] = useState([])
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [totalLength, setTotalLength] = useState(0)
    const [applied, setApplied] = useState([])

    useEffect(() => {
        async function getData() {
            let docs = await getApiCall("/admin/jobs/employer/" + employerId, {}, true);
            if (docs.status) {
                setPage(docs.page)
                setTotalPage(docs.total)
                setTotalLength(docs.total * 10)
                let records = docs.data.map((el, i) => {
                    return  <tr>
                                <td scope="row">
                                    { i + 1 }
                                </td>
                                <td>{ el.jobTitle }</td>
                                <td>{ JOB_TYPE[el.jobType] }</td>
                                <td>{ moment(el.created).format('DD-MM-YYYY') }</td>
                                <td>{ el.location }</td>
                                <td>{ (el.CompletionDate) ? el.CompletionDate : 'Not available' }</td>
                                <td className="text-end">
                                    <a href="#AppliedCandidatesModal" data-bs-toggle="modal" className="btn btn-light btn-sm btn-icon m-1" onClick={ ()=> { viewAppliedCondidateList(el._id) } }><img src="/images/eye.svg" /></a>
                                </td>
                            </tr>
                });
                setRecords(records);
            } else {
                toast.error(docs.message != undefined ? docs.message : "Network error!!")
            }
        }
        getData()
    }, []);

    const getRecords = async (page) => {
        let docs = await getApiCall("/admin/jobs/employer/" + employerId, { page }, true);
        if (docs.status) {
            setPage(docs.page)
            setTotalPage(docs.total)
            setTotalLength(docs.total * 10)
            
            let records = docs.data.map((el, i) => {
                return  <tr>
                            <td scope="row">
                                { i + 1 }
                            </td>
                            <td>{ el.jobTitle }</td>
                            <td>{ JOB_TYPE[el.jobType] }</td>
                            <td>{ moment(el.created).format('DD-MM-YYYY') }</td>
                            <td>{ el.location }</td>
                            <td>{ (el.CompletionDate) ? el.CompletionDate : 'Not availabel' }</td>
                            <td className="text-end">
                                <a href="#AppliedCandidatesModal" data-bs-toggle="modal" className="btn btn-light btn-sm btn-icon m-1"><img src="/images/eye.svg" /></a>
                            </td>
                        </tr>
            });
            setRecords(records);

        } else {
            toast.error(docs.message != undefined ? docs.message : "Network error!!")
        }
    }
    const pagination = async (page) => {
        console.log(page)
        await getRecords(page)
    }

    async function viewAppliedCondidateList(id) {
        let docs = await getApiCall("/admin/jobs/applied/" + id, {}, true);
        if (docs.status) {

            let records = docs.data.map((el, i) => {
                return <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="me-auto d-flex">
                                <div className="dashboard-icon me-2"> <img src={ el.jobseekers.profileImage } /></div>
                                <div>
                                    { el.jobseekers.fullName }
                                    {/* <span className="small">MGSR254784574</span> */}
                                </div>
                            </div>
                            <div>
                                <div className="text-secondary">Applied On</div>
                                { moment(el.createdAt).format('DD-MM-YYYY') }
                            </div>
                        </li>
            })

            setApplied(records);
        } else {
            toast.error(docs.message != undefined ? docs.message : "Network error!!")
        }
    }


    return (
        <>
            <div className="page-header back-page">
                <h4><Link className="back-arrow" to="/employer-management"><img src="/images/back-arrow.svg" /></Link> View Job History</h4>
            </div>
            <div className="table-card">
                <div className="table-reponsive">
                    <table className="table table-white">
                        <thead>
                            <tr className="table-tr-light">
                                <th scope="col" className="text-uppercase">Sr.No </th>
                                <th scope="col" className="text-uppercase">Job name</th>
                                <th scope="col" className="text-uppercase">Job type</th>
                                <th scope="col" className="text-uppercase">Uploaded on date</th>
                                <th scope="col" className="text-uppercase">Job location</th>
                                <th scope="col" className="text-uppercase">Completed on date</th>
                                <th scope="col" className="text-uppercase text-end">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            { records }
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="modal fade" id="AppliedCandidatesModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center text-center border-0 text-center">
                            <h4 className="modal-title w-100">Applied Candidates</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body modal-dialog-scrollable">
                            <div className="login-white-box shadow-none pt-1">
                                <div className="candidates-list">
                                    <ol className="list-group list-group-flush">
                                        { applied }
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <nav aria-label="Page navigation example">
                <Pagination total={totalLength} limit={10} activePage={page} prev last next first onChangePage={pagination} className="pagination justify-content-center navigation-number" />
            </nav>
        </>
        );
};

export default ViewJobHistory;